import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import IconMore from "@cx/ui/Icons/IconMore";
import Dropdown from "@cx/ui/Dropdown";
import Tooltip from "@cx/ui/Tooltip";
import isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import { formatPhoneNumberWithTypeLabel } from "../../../utils/formatter.util";
import { AppContext } from "../../../state/app-context";
import {
  ADD_CUSTOMER,
  ADD_CUSTOMER_ADD_VEHICLE,
  EDIT_CUSTOMER,
  ADD_VEHICLE,
  EDIT_VEHICLE,
  SKIP_CUSTOMER,
  VEHICLE_RESULTS
} from "../../../constants/pages.constants";
import { useNewQuoteContext, Actions } from "../../../state/NewQuoteContext";
import { getVehicleImage } from "../../../api/xmmAxios";
import quoteStatus from "../../../constants/quote-status.constants";
import ConfirmPopup from "../../ui/modals/ConfirmPopup";
import quoteService from "../../quote-summary/services/quote.service";
import format from "../../repair-order/utils/format";
import ChargeAccountDetails from "../../repair-order/components/customer-charge-account/charge-account-details.component";
import "./selected-customer-vehicle.scss";
import { appTypes } from "../../../constants/app.constants";
import { isDMSPlus } from "../../../api/app.util";

const ActiveCustomerVehicle = props => {
  const { selectIfEmpty } = props;
  const appContext = useContext(AppContext);
  const { localeStrings, windowSize, locale } = appContext;
  const { state, dispatch } = useNewQuoteContext();
  const {
    appType,
    customer,
    vehicle,
    makes,
    quoteSummary,
    editCustomerVehicleAccess,
    chargeAccountInfo,
    serviceContracts
  } = state;

  const isCSR = appType === "CSR";
  const currentQuoteStatus = quoteSummary?.quoteStatus || "";
  const [showDiscardPopup, setShowDiscardPopup] = useState(false);
  const isVehicleExist = !vehicle || !vehicle.metaVehicleId ? false : true;
  const isCustomerExist = !customer || !customer.personId ? false : true;
  const clsVehicleImg = windowSize.width < 428 ? "hide" : "vehicle-img";
  let supportedMakes = [];
  let customerClass = "";
  if (
    (!customer || !customer.personId) &&
    (state.currentPage === ADD_CUSTOMER ||
      state.currentPage === ADD_CUSTOMER_ADD_VEHICLE)
  ) {
    customerClass = "disabled";
  }
  let vehicleClass = "";
  if (
    ((!vehicle || !vehicle.metaVehicleId) &&
      state.currentPage === SKIP_CUSTOMER) ||
    state.currentPage === ADD_VEHICLE
  ) {
    vehicleClass = "disabled";
  }

  let firstName = "";
  let lastName = "";
  let email = "";
  let formattedPhone = "";
  if (!isEmpty(customer)) {
    firstName = customer.firstName;
    lastName = customer.lastName;
    email = !customer.email ? "" : customer.email;
    formattedPhone = formatPhoneNumberWithTypeLabel(
      customer.contactInfo.phoneNumbers
    );
  }
  const showEditVehicle = () => {
    dispatch({
      type: Actions.SET_CURRENT_PAGE,
      payload: EDIT_VEHICLE
    });
  };
  const showEditCustomer = () => {
    dispatch({
      type: Actions.SET_CURRENT_PAGE,
      payload: EDIT_CUSTOMER
    });
  };

  const handleSelectVehicleClick = () => {
    if (currentQuoteStatus === quoteStatus.PROGRESS) {
      setShowDiscardPopup(true);
    } else {
      selectOtherVehicle();
    }
  };
  const selectOtherVehicle = () => {
    dispatch({
      type: Actions.SET_CURRENT_PAGE,
      payload: VEHICLE_RESULTS
    });
    dispatch({
      type: Actions.SET_VEHICLE,
      payload: null
    });
    dispatch({
      type: Actions.SET_VEHICLE_FUTURE_APPOINTMENT,
      payload: { hasFutureAppointments: false }
    });
  };
  const handleDiscardPopupProceed = () => {
    selectOtherVehicle();
    clearQuoteServices();
  };
  const handleDiscardPopupCancel = () => {
    setShowDiscardPopup(false);
  };
  // remove services from quote using rest call
  const clearQuoteServices = () => {
    if (!isEmpty(quoteSummary)) {
      quoteSummary.quoteServices = [];
    }
    dispatch({
      type: Actions.UPDATE_QUOTE_SERVICE,
      payload: quoteSummary
    });
    removeQuoteServices();
  };
  const removeQuoteServices = async () => {
    try {
      const response = await quoteService.clearQuoteServices({
        appContext,
        customer,
        vehicle,
        quoteSummary
      });
      let quoteResponse = null;
      if (!isEmpty(response)) {
        const { quoteId, confirmationId } = response;
        let message = "";
        if (!confirmationId && !quoteId) {
          message =
            "Services are not available for this vehicle at this time.  Please check back later.";
          quoteResponse = {
            quoteId: null,
            confirmationId: null,
            quoteServices: [],
            message
          };
        } else {
          if (isEmpty(response.quoteServices)) response.quoteServices = [];
          response.message = "";
          quoteResponse = Object.assign({}, response);
        }
        dispatch({
          type: Actions.UPDATE_QUOTE,
          payload: quoteResponse
        });
      }
    } catch (error) {
      const msg = error["message"]
        ? error.message
        : localeStrings["sq.errors.network.error"];
      console.error(msg);
    }
  };
  const handleSelectCustomerLabelClick = () => {
    if (
      (!customer || !customer.personId) &&
      (state.currentPage === ADD_CUSTOMER ||
        state.currentPage === ADD_CUSTOMER_ADD_VEHICLE)
    ) {
      return;
    }
    // @note: skip customer flow, add new customer for vehicle added
    if (!isEmpty(vehicle) && isEmpty(customer)) {
      dispatch({
        type: Actions.SET_CURRENT_PAGE,
        payload: ADD_CUSTOMER_ADD_VEHICLE
      });
      dispatch({
        type: Actions.SET_IS_SKIP_CUSTOMER_FLOW,
        payload: true
      });
    } else {
      if (isEmpty(vehicle)) {
        dispatch({
          type: Actions.SET_CURRENT_PAGE,
          payload: ADD_CUSTOMER
        });
      }
    }
  };
  const handleSelectVehicleLabelClick = () => {
    if (
      ((!vehicle || !vehicle.metaVehicleId) &&
        state.currentPage === SKIP_CUSTOMER) ||
      state.currentPage === ADD_VEHICLE
    ) {
      return;
    }

    if (!isEmpty(customer) && isEmpty(vehicle)) {
      if (isEmpty(_get(customer, "vehicles", []))) {
        dispatch({
          type: Actions.SET_CURRENT_PAGE,
          payload: ADD_VEHICLE
        });
      } else {
        if (state.currentPage === VEHICLE_RESULTS) {
          dispatch({
            type: Actions.SET_CURRENT_PAGE,
            payload: ADD_VEHICLE
          });
        } else {
          dispatch({
            type: Actions.SET_CURRENT_PAGE,
            payload: VEHICLE_RESULTS
          });
        }
      }
    } else {
      if (isEmpty(vehicle)) {
        dispatch({
          type: Actions.SET_CURRENT_PAGE,
          payload: SKIP_CUSTOMER
        });
      }
    }
  };

  useEffect(() => {
    if (vehicle && vehicle.image === undefined) {
      const { make, model, year } = vehicle;
      const countryCode = locale.split("_")[1];
      vehicle.image = getVehicleImage(make, model, year, countryCode);
    }
  }, [vehicle, locale]);
  let editVehicleTip = "";
  let editVehicleOptions = [];
  if (isVehicleExist) {
    supportedMakes = makes.filter(obj => {
      return obj.make === vehicle.make;
    });
    if (supportedMakes.length > 0) {
      editVehicleOptions = [
        {
          label: localeStrings["sq.newquote.customer.edit_vehicle_lbl"],
          onSelect: showEditVehicle,
          value: "editvehicle"
        },
        {
          label:
            localeStrings["sq.newquote.customer.select_another_vehicle_lbl"],
          onSelect: handleSelectVehicleClick,
          value: "selectothervehicle",
          disabled: isCustomerExist ? false : true
        }
      ];
    } else {
      editVehicleTip = localeStrings[
        "sq.newquote.vehicle.unsupported_make_msg"
      ].replace("%1", vehicle.make);
      editVehicleOptions = [
        {
          label:
            localeStrings["sq.newquote.customer.select_another_vehicle_lbl"],
          onSelect: handleSelectVehicleClick,
          value: "selectothervehicle",
          disabled: isCustomerExist ? false : true
        }
      ];
    }
  }
  const editVehicleDropdown =
    isVehicleExist &&
    editCustomerVehicleAccess &&
    currentQuoteStatus !== quoteStatus.SENT ? (
      <Dropdown
        buttonStyle="link"
        icon={<IconMore />}
        htmlId="vehicleMenu"
        name="vehicleMenuIcon"
        className="xmm-dotted-dropdown btn--icon"
        pullRight
        options={editVehicleOptions}
        disabled={state.currentPage === EDIT_VEHICLE}
      />
    ) : (
      ""
    );
  const vehicleEmpty = selectIfEmpty ? (
    <div className="cust-empty-boxes">
      <span
        className={vehicleClass}
        role="button"
        onClick={handleSelectVehicleLabelClick}
      >
        {localeStrings["sq.newquote.customer.select_vehicle_lbl"]}
      </span>
    </div>
  ) : (
    <div className={clsVehicleImg} />
  );
  const vehicleCard = isVehicleExist ? (
    <>
      <div
        className={clsVehicleImg}
        style={{ backgroundImage: `url(${vehicle.image})` }}
      />
      <div className="select-cust-info" id="selectedVehicleInfo">
        <div className="flex select-cust-info-content">
          <b>
            {vehicle.year || ""} {vehicle.make || ""} {vehicle.model || ""}
          </b>
        </div>
        <div className="select-cust-more">
          <span className="cust-box-empty">
            {vehicle.trim || ""} {vehicle.engineSize || ""}{" "}
            {vehicle.driveType || ""} {vehicle.transmissionType || ""}
          </span>
        </div>
        <div className="select-cust-more">
          <span
            className={`cust-box-empty ${vehicle.vin ? "" : "missing-vin"}`}
          >
            {vehicle.vin || (isCSR ? "Missing VIN" : "")}
          </span>
        </div>
        {isCSR ? (
          <div className="select-cust-more">
            <span className="cust-box-empty">
              {"Mileage in/out: "}
              {quoteSummary.mileageIn
                ? format.decimal(quoteSummary.mileageIn, 0)
                : "--"}
              /
              {quoteSummary.mileageOut
                ? format.decimal(quoteSummary.mileageOut, 0)
                : "--"}
            </span>
          </div>
        ) : null}
      </div>
      <div className="select-cust-action">
        {supportedMakes.length === 0 ? (
          <Tooltip
            htmlId="tipEditVehicle"
            tooltipContent={editVehicleTip}
            position="top"
          >
            {editVehicleDropdown}
          </Tooltip>
        ) : (
          editVehicleDropdown
        )}
      </div>
    </>
  ) : (
    vehicleEmpty
  );

  // hide edit customer details links when RO is finalized or moved to archive
  const isHideEditCustomerDetailsLink = quoteSummary?.payers?.some(
    p => !!p.closedDate
  );
  const editCustomerDetailsLink =
    isCSR &&
    isDMSPlus(appContext?.dealer?.dmsType) &&
    !isHideEditCustomerDetailsLink &&
    (appContext?.userPermissions?.canPerformServiceAdvisorActions ||
      appContext?.userPermissions?.canPerformServiceManagerActions) ? (
      <div
        className="edit-customer-link hand-cursor"
        id="editCustomerLink"
        onClick={
          state.currentPage === EDIT_CUSTOMER
            ? e => e.preventDefault()
            : showEditCustomer
        }
      >
        {localeStrings["csr.ro.details.customer.edit_customer_lbl"]}
      </div>
    ) : null;

  const customerCard = isCustomerExist ? (
    <>
      {" "}
      <div className="select-cust-info" id="selectedCustomerInfo">
        <div className="customer-name-details">
          <b>
            {firstName} {lastName}
          </b>
          {!isEmpty(chargeAccountInfo) ? (
            <ChargeAccountDetails chargeAccountInfo={chargeAccountInfo} />
          ) : null}
        </div>
        <div className="select-cust-more">{email}</div>
        <div className="select-cust-more">{formattedPhone}</div>
        <div className="select-cust-more">{editCustomerDetailsLink}</div>
      </div>
      <div className="select-cust-action">
        {isCustomerExist &&
        editCustomerVehicleAccess &&
        currentQuoteStatus !== quoteStatus.SENT ? (
          <Dropdown
            buttonStyle="link"
            icon={<IconMore />}
            htmlId="customerMenu"
            name="customerMenuIcon"
            className="xmm-dotted-dropdown btn--icon"
            pullRight
            disabled={state.currentPage === EDIT_CUSTOMER}
            options={[
              {
                label: localeStrings["sq.newquote.customer.edit_customer_lbl"],
                onSelect: showEditCustomer,
                value: "editcustomer"
              }
            ]}
          />
        ) : (
          ""
        )}
      </div>
    </>
  ) : (
    <div className="cust-empty-boxes">
      <span
        className={customerClass}
        role="button"
        onClick={handleSelectCustomerLabelClick}
      >
        {
          localeStrings[
            "sq.newquote.active_customer_vehicle.select_customer_lbl"
          ]
        }
      </span>
    </div>
  );
  const discardPopup = (
    <ConfirmPopup
      title={localeStrings["sq.common.alert_lbl"]}
      message={localeStrings["sq.newquote.vehicle.select_vehicle_discard_msg"]}
      show={showDiscardPopup}
      okText={localeStrings["sq.common.proceed_button"]}
      cancelText={localeStrings["sq.common.cancel_button"]}
      okAction={handleDiscardPopupProceed}
      cancelAction={handleDiscardPopupCancel}
      hideCancel={false}
      hideOk={false}
      buttonStyle="danger"
    />
  );

  // if (appContext.appType === "CSR") {
  //   return (
  //     <>
  //       <div className="select-cust-outer">
  //         <div className="select-cust-box">{vehicleCard}</div>
  //         <div className="select-cust-box">{customerCard}</div>
  //       </div>
  //       {discardPopup}
  //     </>
  //   );
  // }
  return (
    <>
      <div className="select-cust-outer">
        <div className="select-cust-box">{customerCard}</div>
        <div className="select-cust-box">{vehicleCard}</div>
      </div>
      {discardPopup}
    </>
  );
};

ActiveCustomerVehicle.propTypes = {
  selectIfEmpty: PropTypes.bool
};

ActiveCustomerVehicle.defaultProps = {
  selectIfEmpty: true
};

export default ActiveCustomerVehicle;
