/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState } from "react";
import Badge from "@cx/ui/Badge";
import csrService from "../../services/csr.service";
import TopLevelMenuModal from "../../../repair-order/components/top-level-menu-modal.component";
import SubHeader from "../../../repair-order/components/sub-header/sub-header.component";
import { useNewQuoteContext, Actions } from "../../../../state/NewQuoteContext";
import { buildROSpecialOrderStatusBadge } from "../../../../utils/quote.util";
import {
  PAY_TYPE_GROUPS,
  RO_STATUSES,
  TECH_STATUSES
} from "../../../repair-order/constants/csr.constants";
import "./ro-details-header.scss";
import moment from "moment";
import { AppContext } from "../../../../state/app-context";
import QuoteStatusConstant from "../../../../constants/quote-status.constants";
import RoDetailsHeaderMenuOptions from "./components/ro-details-header-menu-options.component";

const RepairOrderHeader = () => {
  // REFERENCE: RO Details header - UX: https://www.sketch.com/s/8e61c245-4ea7-4e43-a0de-10834a4a2fe5/a/g10RpKz

  const [currentModalType, setCurrentModalType] = useState(null);
  const { state, dispatch } = useNewQuoteContext();
  const { quoteSummary, advisors } = state;
  const { quoteStatus } = quoteSummary;
  const roNumberDisplay = csrService.getRONumberDisplay(quoteSummary);
  const transportType = quoteSummary?.transportation?.start?.transportType;
  const isWaiter = transportType?.includes("WAITER");
  const appContext = useContext(AppContext);
  const { appSource, user, dealerProperties } = appContext;
  const legitPayers = csrService.getLegitPayers(quoteSummary, dealerProperties);
  const advisorIdCurrentlyAssigned = quoteSummary.serviceWriter;
  const advisorIdThatShouldBeAssigned = csrService.determineAdvisorToAutoAssign(
    user,
    quoteSummary,
    advisors
  );

  if (advisorIdCurrentlyAssigned !== advisorIdThatShouldBeAssigned) {
    dispatch({
      type: Actions.SET_ADVISOR,
      payload: advisorIdThatShouldBeAssigned
    });
  }

  // @note: When RO is opened from Engage, and the status is other than WITH_ADVISOR,
  // only the RO Number and the status badge are rendered and the roStatusInfo.cssClass is not used.
  // This whole component (RepairOrderHeader) will not render when the status is WITH_ADVISOR.
  const isEngageSource = appSource === "ENG";

  if (!quoteSummary || !quoteStatus) {
    return null;
  }

  const roStatusInfo = RO_STATUSES[quoteStatus];
  const techStatus =
    quoteSummary.quoteServices.map(c => c.technicians).flat().length > 0
      ? "ASSIGNED"
      : "UNASSIGNED";

  const promised = moment(quoteSummary.pickUpDateTime);
  const promisedDate = promised.isSame(new Date(), "day")
    ? "Today"
    : promised.format("M/D");
  const promisedTime = promised.format("h:mm");
  const promisedSuffix = promised.format("A");

  const statusBadge = (uniqueId, status) => {
    return status ? (
      <Badge
        htmlId={uniqueId}
        key={uniqueId}
        color={status.cxColor}
        className={`badge-${status.cssClass}`}
      >
        {status.displayText}
      </Badge>
    ) : null;
  };

  const handleCloseTopLevelMenuComponent = () => {
    setCurrentModalType(null);
  };
  /**
   *
   * @param {String} type
   */
  const selectMenuItem = type => {
    setCurrentModalType(type);
  };
  const showTechnicianStatus =
    !isEngageSource && ["IN_PROCESS"].includes(quoteStatus);

  const showPayerStatuses =
    !isEngageSource &&
    ["WORK_FINISHED", "PRE_INVOICE", "FINALIZED", "PRE_RO"].includes(
      quoteStatus
    );

  const payerSortFunction = (a, b) => {
    return (
      PAY_TYPE_GROUPS.findIndex(ptg => ptg.value === a.payType) -
      PAY_TYPE_GROUPS.findIndex(ptg => ptg.value === b.payType)
    );
  };

  return (
    <>
      <div
        className={`ro-details-header ${
          isEngageSource ? "engage-source" : roStatusInfo?.cssClass
        }`}
      >
        <div id="mainGroup">
          <div id="roId" className="ro-number-container">
            {quoteStatus !== "PRE_RO" ? <label>RO</label> : null}
            <span className="ro-number">{roNumberDisplay}</span>
          </div>
          <div className="status-container ro-status">
            {statusBadge("roStatus", roStatusInfo)}
          </div>
          {showTechnicianStatus ? (
            <div id="techStatus" className="status-container">
              <label>Technician</label>
              {statusBadge("techStatus", TECH_STATUSES[techStatus])}
            </div>
          ) : null}
          {quoteSummary?.specialOrderStatus?.length ? (
            <div id="specialOrderStatus" className="status-container">
              <label>Special order status</label>
              {buildROSpecialOrderStatusBadge(
                quoteSummary.roId,
                quoteSummary.specialOrderStatus
              )}
            </div>
          ) : null}
          {showPayerStatuses
            ? legitPayers?.sort(payerSortFunction).map(payer => {
                const uniqueId = `payTypeStatus${payer.payType}`;
                return (
                  <div
                    id={uniqueId}
                    key={uniqueId}
                    className="status-container"
                  >
                    <label>{payer.payType}</label>
                    <Badge
                      htmlId={`payerBadge${payer.payType}`}
                      color={payer.closedDate ? "purple" : "gray"}
                    >
                      {payer.closedDate
                        ? `Closed - ${moment(payer.closedDate).format("MM/DD")}`
                        : "Pending"}
                    </Badge>
                  </div>
                );
              })
            : null}
        </div>
        {!(isEngageSource || quoteStatus === QuoteStatusConstant.PRE_RO) ? (
          <div id="secondaryGroup">
            <div className="item-container hang-tag">
              <label>Hang tag</label>
              <span className="value">
                {state?.quoteSummary?.hangTag || "---"}
              </span>
            </div>
            <div className="item-container promised">
              <label>Promised</label>
              {quoteSummary.pickUpDateTime ? (
                <>
                  <span className="promised-date">{promisedDate}</span>
                  <span className="promised-time">{promisedTime}</span>
                  <span className="promised-suffix">{promisedSuffix}</span>
                </>
              ) : (
                <span className="value">---</span>
              )}
            </div>
            {transportType?.length ? (
              <div className="item-container transportation">
                <span className={isWaiter ? "waiter" : ""}>
                  {transportType}
                </span>
              </div>
            ) : null}
            <RoDetailsHeaderMenuOptions
              roNumberDisplay={roNumberDisplay}
              onSelectMenuItem={selectMenuItem}
            />
          </div>
        ) : null}
      </div>
      {currentModalType !== null ? (
        <TopLevelMenuModal
          currentModalType={currentModalType}
          callbackCloseAction={handleCloseTopLevelMenuComponent}
        />
      ) : null}
      {!isEngageSource ? <SubHeader /> : null}
    </>
  );
};

export default RepairOrderHeader;
