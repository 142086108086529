// EXTERNAL LIBRARIES
import React, { useContext } from "react";
import IconMore from "@cx/ui/Icons/IconMore";
import Dropdown from "@cx/ui/Dropdown";
import PropTypes from "prop-types";
// CUSTOM COMPONENTS
import {
  CHANGE_ADVISOR,
  CHANGE_MILEAGE_IN,
  CHANGE_MILEAGE_OUT,
  CHANGE_PROMISED_TIME,
  CHANGE_TRANSPORTATION,
  HANGTAG
} from "../../../../repair-order/constants/adjustment.constant";
import { AppContext } from "../../../../../state/app-context";
import { useNewQuoteContext } from "../../../../../state/NewQuoteContext";
import { RO_STATUSES } from "../../../../repair-order/constants/csr.constants";
import { openNewWindow } from "../../../../utils/new-window.util";
import { formatKeysToUpperCase } from "../../../../../utils/object";

const RoDetailsHeaderMenuOptions = ({ roNumberDisplay, onSelectMenuItem }) => {
  const appContext = useContext(AppContext);
  const { state } = useNewQuoteContext();
  const { quoteSummary } = state;
  const { quoteStatus } = quoteSummary;
  const { userPermissions, sourceSystem } = appContext;
  const isWithAdvisor = quoteStatus === RO_STATUSES.WITH_ADVISOR.value;
  const isFinalized = quoteStatus === RO_STATUSES.FINALIZED.value;
  const roDetailsMenuOptions = [];

  if (userPermissions.canUpdateROAttributes) {
    roDetailsMenuOptions.push(
      {
        label: "Change advisor",
        value: "advisor",
        disabled: isWithAdvisor || isFinalized,
        onSelect: () => {
          onSelectMenuItem(CHANGE_ADVISOR);
        }
      },
      {
        label: "Change hang tag",
        value: "hangTag",
        disabled: isWithAdvisor || isFinalized,
        onSelect: () => {
          onSelectMenuItem(HANGTAG);
        }
      },
      {
        label: "Change mileage in",
        value: "mileageIn",
        disabled: isWithAdvisor || isFinalized,
        onSelect: () => {
          onSelectMenuItem(CHANGE_MILEAGE_IN);
        }
      }
    );
  }
  if (!isWithAdvisor && !isFinalized && userPermissions.canUpdateROMileageOut) {
    roDetailsMenuOptions.push({
      label: "Change mileage out",
      value: "mileageOut",
      disabled: !!sourceSystem,
      onSelect: () => {
        onSelectMenuItem(CHANGE_MILEAGE_OUT);
      }
    });
  }
  if (userPermissions.canUpdateROAttributes) {
    roDetailsMenuOptions.push(
      {
        label: "Change transportation",
        value: "transportation",
        disabled: isFinalized,
        onSelect: () => {
          onSelectMenuItem(CHANGE_TRANSPORTATION);
        }
      },
      {
        label: "Change promised time",
        value: "promisedTime",
        disabled: isFinalized,
        onSelect: () => {
          onSelectMenuItem(CHANGE_PROMISED_TIME);
        }
      }
    );
  }
  if (roDetailsMenuOptions.length) {
    roDetailsMenuOptions[roDetailsMenuOptions.length - 1].divider = true;
  }
  const isHistoricalRepairOrder = !!sourceSystem;
  const modalRoute = isHistoricalRepairOrder
    ? "/archivedeventlog"
    : "/servicelog";
  // This menu item is available to everyone, all the time.
  roDetailsMenuOptions.push({
    label: "View activity log",
    value: "roLog",
    onSelect: () => {
      openNewWindow(
        modalRoute,
        null,
        quoteSummary.confirmationId,
        roNumberDisplay,
        0,
        0,
        900,
        500,
        `RO ${roNumberDisplay} - Activity log`,
        isHistoricalRepairOrder
          ? JSON.stringify({
              customer: {
                ...quoteSummary.customer,
                contactInfo: {
                  phoneNumbers: formatKeysToUpperCase(
                    quoteSummary.customer.contactInfo?.phoneNumbers
                  )
                }
              },
              vehicle: quoteSummary.vehicle,
              mileageIn: quoteSummary.mileageIn,
              mileageOut: quoteSummary.mileageOut,
              eventLogs: quoteSummary.eventLogs ?? []
            })
          : null
      );
    }
  });

  // REFERENCE: Void RO - User Story: https://rally1.rallydev.com/#/644875473405d/myrally?detail=%2Fuserstory%2F817540218175%2Fdetails&fdp=true
  // REFERENCE: Void RO - UX: https://www.figma.com/design/nVz6uqktMBbR7F7eUfCWBa/RO-Details?node-id=3038-11847&p=f&t=VTdXHvSXdhKeAzFQ-0

  return (
    <Dropdown
      icon={<IconMore isActive={true} />}
      htmlId="csrActionBtn"
      name="csrActionBtn"
      id="csrActionBtn"
      buttonStyle="link"
      size="small"
      options={roDetailsMenuOptions}
      pullRight
    />
  );
};

export default RoDetailsHeaderMenuOptions;

RoDetailsHeaderMenuOptions.propTypes = {
  // handlers
  onSelectMenuItem: PropTypes.func.isRequired,
  // data
  roNumberDisplay: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
};
