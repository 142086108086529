/* eslint-disable no-console */
import React, { useEffect, useContext, useState, useRef } from "react";
import themePrefixer from "@cx/ui/themePrefixer";
import Button from "@cx/ui/Button";
import IconKeyboardArrowDown from "@cx/ui/Icons/IconKeyboardArrowDown";
import IconKeyboardArrowRight from "@cx/ui/Icons/IconKeyboardArrowRight";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { AppContext } from "../../state/app-context";
import { useNewQuoteContext, Actions } from "../../state/NewQuoteContext";
import { SEARCH_SERVICE } from "../../constants/pages.constants";
import ServiceDetails from "./ServiceDetails";
import quoteService from "./services/quote.service";
import editQuoteService from "./services/edit-quote.service";
import { fixCSR, loadQuote } from "../page-wrapper/services/quote-api.service";
import serviceCache from "../utils/service-cache.util";
import useComponentDidMount from "../../hooks/useComponentDidMount";
import * as gtmEvent from "../utils/gtag/gtag-event.util";
import ToggleSwitch from "@cx/ui/ToggleSwitch";
// @csr-logic
import CheckoutSummaryComponent from "./components/checkout-summary.component";
import EmergencyPartsGrid from "../../PartsLookupModule/components/emergency-parts-grid.component";
import { openNewWindow } from "../utils/new-window.util";
import util from "../repair-order/utils/util";
import csrService from "./services/csr.service";
import ApproveEstimateModal from "../repair-order/components/approve-estimate/approve-estimate-modal.component";
import FinalizeROModal from "../repair-order/components/finalize-ro/finalize-ro-modal.component";
import FinalizeROConfirmationModal from "../repair-order/components/finalize-ro/finalize-ro-confirmation-modal.component";
import DocumentsModal from "../repair-order/components/documents-modal/documents-modal.component";
import ErrorBoundary from "../repair-order/components/error-boundary.component";
import {
  ADD_CATALOG_RO_FEE,
  ADD_CATALOG_RO_LEVEL_DISCOUNT,
  YES
} from "../repair-order/constants/adjustment.constant";
import {
  PAYER_TYPES,
  PAYMENT_TYPES,
  RO_STATUSES,
  SERVICE_CONTRACT_TYPES
} from "../repair-order/constants/csr.constants";
import { toast } from "@cx/ui/Toast";
import QuoteSummary from "./components/quote-summary-component";
import AlertBox from "../ui/banners/AlertBox.js";
import ConfirmPopup from "../ui/modals/ConfirmPopup";
import QuoteStatusHeader from "../quote/quote-header/quote-status-header-component";
import QuoteStatusConstant, {
  roStatusOptions
} from "../../constants/quote-status.constants";
import { buildActivityLogMessage } from "../../utils/string.util";
import { getUpdatedServicesWithDisplayOrder } from "../page-wrapper/utils/service.util";
import {
  createPreRo,
  updateDisplayOrderForServices,
  updateOverridePrevRoNumber
} from "./services/service-details.service";
import {
  hasEditQuoteAccessNew,
  sortQuoteServices
} from "../page-wrapper/utils/quote-util";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove
} from "@dnd-kit/sortable";
import { appSources } from "../../constants/app.constants";
import moment from "moment";
import ComebackModalDialog from "./components/comeback-modal.component";
import ModalDialog from "@cx/ui/ModalDialog";
import { sortByLastTwoCharacters } from "../utils/sorting.util";
import { QuoteServiceTypes } from "../page-wrapper/constants/page-wrapper.constants";
import ReasonModal from "./components/reason-modal.component";
import PrintPickListComponent from "./components/print-pick-list.component";
import ServiceContractModal from "../service-contract/service-contract-modal.component";
import {
  handleROPricingBanner,
  hasPayerWithoutServiceContract,
  hasWarrantyPayerWithWarrantyPlanWithoutApprovalCode,
  isPromisedTimeBeforeCheckInDateTime,
  updateROPricingCache,
  getPayerByType
} from "../repair-order/utils/payers.util";
import pastQuotesService from "../past-quotes/services/past-quotes.service";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import PreRoDropdown from "./components/pre-ro-dropdown.component";
import { updateOpenerWindow } from "../../utils/window.util";
import SpecialPartsBannerContainer from "../repair-order/components/service-special-parts/components/special-parts-banner-container.component";
import { canUseSpecialOrderParts } from "../repair-order/components/service-special-parts/utils/service-special-parts.utlis";
import { isDMSPlus } from "../../api/app.util";

function ServiceSummary() {
  const appContext = useContext(AppContext);
  const {
    isPartsView,
    localeStrings,
    userPermissions,
    appType,
    appSource,
    user,
    sourceSystem,
    dealerProperties
  } = appContext;
  const { blockUntilCompleted, state, dispatch } = useNewQuoteContext();
  const [showModal, setShowModal] = useState(false);
  const [showMailBanner, setShowMailBanner] = useState(false);
  const [showServiceBanner, setShowServiceBanner] = useState(false);
  const [showCompleteServiceBanner, setShowCompleteServiceBanner] =
    useState(false);
  const [showPartsAlert, setShowPartsAlert] = useState(false);
  const [showCompletePartsBanner, setShowCompletePartsBanner] = useState(false);
  const [showRequestedFailAlert, setShowRequestedFailAlert] = useState(false);
  const [showResolvingFailAlert, setResolvingFailAlert] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showROPricingBanner, setShowROPricingBanner] = useState(false);
  const [currentModeAndEvent, setCurrentModeAndEvent] = useState({
    mode: null,
    event: null
  });
  const [archivedQuoteData, setArchivedQuoteData] = useState(null);
  const [loadingArchivedData, setLoadingArchivedData] = useState(false);
  const [showAddPurchaseModal, setShowAddPurchaseModal] = useState(false);
  const [showPreROReasonModal, setShowPreROReasonModal] = useState(false);
  const [emergencyParts, setEmergencyParts] = useState([]);
  const [purchaseOrdersList, setPurchaseOrdersList] = useState([]);
  const [showEmergencyPartError, setShowEmergencyPartError] = useState(false);
  const [showCreatePoError, setShowCreatePoError] = useState(false);
  const comebackModalRef = useRef(null);

  const {
    advisors,
    customer,
    vehicle,
    quoteSummary,
    originalQuoteSummary,
    requestStatus,
    MailToCustomerSent,
    preRoServiceList
  } = state;
  const {
    quoteId,
    confirmationId,
    // workflowAttentionTag: attentionTags,
    quoteServices,
    shadowServices
  } = quoteSummary;

  const isDmsPlusQuote = sourceSystem && sourceSystem === "DMS+";
  const isNormalQuote = !sourceSystem;

  const isSent = quoteSummary.quoteStatus === QuoteStatusConstant.SENT;
  const hasServiceContract = !hasPayerWithoutServiceContract(quoteSummary);
  const serviceContractPayer = getPayerByType(quoteSummary, PAYER_TYPES.S);
  const isDMSPlusDealerWithOtherPayer =
    isDMSPlus(appContext?.dealer?.dmsType) &&
    serviceContractPayer?.serviceContract?.type === SERVICE_CONTRACT_TYPES.OT;
  const isUseOEMWarrantyEnabled =
    dealerProperties?.ENGG_USE_OEM_WARRANTY === YES ? true : false;

  const hasWarrantyApprovalCode =
    !hasWarrantyPayerWithWarrantyPlanWithoutApprovalCode(quoteSummary);

  const docsButtonVisible =
    quoteSummary.quoteStatus !== roStatusOptions.WITH_ADVISOR &&
    quoteSummary.quoteStatus !== QuoteStatusConstant.PRE_RO &&
    !isPartsView &&
    (isNormalQuote || isDmsPlusQuote) &&
    userPermissions.canViewDocuments;

  const dismmissROPricingBanner = async () => {
    setShowROPricingBanner(false);
    await handleROPricingBanner(quoteId, quoteServices);
  };

  const getPastQuotesSource = async (dealerCode, customerId) => {
    try {
      return pastQuotesService.getFinalizedPastQuotes(dealerCode, customerId);
    } catch (error) {
      const msg = !error ? "Error occurred while loading data" : error.message;
      toast.error(msg, {
        closeOnClick: true
      });
    }
  };

  useEffect(() => {
    if (
      quoteSummary &&
      quoteSummary.roNumber &&
      archivedQuoteData === null &&
      isDmsPlusQuote
    ) {
      setLoadingArchivedData(true);
      getPastQuotesSource(
        appContext.dealer.dealerCode,
        quoteSummary.customer.personId
      )
        .then(res => {
          const filteredQuote = res.filter(
            quote => quote.roNumber == quoteSummary.roNumber
          );
          if (filteredQuote.length > 0) {
            setArchivedQuoteData({
              confirmationId: filteredQuote[0].confirmationId,
              quoteId: filteredQuote[0].quoteId
            });
          }
          setLoadingArchivedData(false);
        })
        .finally(() => {
          setLoadingArchivedData(false);
        });
    }
  }, [
    quoteSummary,
    appContext.dealer.dealerCode,
    archivedQuoteData,
    isDmsPlusQuote
  ]);

  useEffect(() => {
    if (MailToCustomerSent) {
      const showBanner = MailToCustomerSent[quoteSummary.confirmationId];
      setShowMailBanner(showBanner);
    }
  }, [quoteSummary, MailToCustomerSent]);

  useEffect(() => {
    if (requestStatus) {
      setShowServiceBanner(requestStatus.showServiceAssistanceRequestAlert);
      setShowCompleteServiceBanner(requestStatus.completeServiceAssistance);
      setShowPartsAlert(requestStatus.showPartsAssistanceRequestAlert);
      setShowCompletePartsBanner(requestStatus.completePartsAssistance);
      setShowRequestedFailAlert(requestStatus.showAttentionRequestFailAlert);
      setResolvingFailAlert(requestStatus.showAttentionRequestFailAlert);
    }
  }, [requestStatus]);

  useEffect(() => {
    if (quoteServices?.length > 0) {
      updateROPricingCache(
        quoteId,
        quoteServices,
        quoteSummary,
        appContext,
        setShowROPricingBanner
      );
    }
  }, [quoteId, quoteServices, quoteSummary, appContext]);

  // @csr-logic
  const isCSR = appType === "CSR";
  const hasVIN = quoteSummary?.vehicle?.vin?.length ? true : false;
  const hasRONumber = util.getType(quoteSummary?.roNumber) === "Number";
  const hasClosedPayers = quoteSummary?.payers?.some(p => !!p.closedDate);
  const isFinalized = quoteSummary?.quoteStatus === "FINALIZED";
  const canProceedWithRO =
    isCSR &&
    !isPartsView &&
    (userPermissions.canApproveEstimate ||
      userPermissions.canStartInvoicing ||
      userPermissions.canFinalizeRO);
  const [showApproveEstimateModal, setShowApproveEstimateModal] =
    useState(false);
  const [showFinalizeROModal, setShowFinalizeROModal] = useState(false);
  const [updatedPayers, setUpdatedPayers] = useState([]);
  const [showFinalizeROConfirmationModal, setShowFinalizeROConfirmationModal] =
    useState(false);
  const [showDocumentsModal, setShowDocumentsModal] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [showDetailsSwitchValue, setShowDetailsSwitchValue] = useState(true);

  const quoteServiceTypes = [
    ...new Set(serviceList.map(s => s.quoteServiceType))
  ];

  const isSplitPaymentEnabled =
    dealerProperties.USE_SPLIT_PAYMENT_FEATURE === YES ? true : false;
  const isEmergencyPartsEnabled =
    dealerProperties.ENABLE_DMSPLUS_EMERGENCY_PARTS === "Y" ? true : false;
  const isTechnicianSeeAllTechServicesEnabled =
    dealerProperties?.USE_TECHTIME_SEE_ALL_TECH_SERVICES === "Y" ? true : false;
  // @csr-logic
  // automatically assign the parts person when applicable
  if (isCSR && isPartsView && !quoteSummary.partsPerson) {
    // TODO: Set properties and call "patch quote" API.
  }

  useEffect(() => {
    const quoteTitle = !confirmationId ? "" : confirmationId;
    dispatch({
      type: Actions.SET_PAGE_TITLE,
      payload: localeStrings["sq.newquote.customer.service_summary_lbl"]
    });
    dispatch({
      type: Actions.SET_PAGE_SUBTITLE,
      payload: quoteTitle
    });
  }, [localeStrings, confirmationId, dispatch]);
  useComponentDidMount(() => {
    if (!isEmpty(confirmationId)) {
      serviceCache.wipeAddedServices(confirmationId);
    }
    // @note: skip customer flow, when customer added, update quote with this customer
    if (!isEmpty(confirmationId) && isEmpty(quoteSummary.customer)) {
      if (!isEmpty(vehicle) && !isEmpty(customer)) {
        updateCustomerInQuote(customer);
      }
    }
    dispatch({
      type: Actions.SET_IS_EDITING_SERVICE,
      payload: false
    });
  });

  useEffect(() => {
    if (isEmpty(quoteServices)) {
      setServiceList([]);
    } else {
      const services = sortQuoteServices(quoteServices, appType);

      setServiceList(services);
    }
  }, [appType, quoteServices]);

  const gotoAddServices = () => {
    dispatch({
      type: Actions.SET_CURRENT_PAGE,
      payload: SEARCH_SERVICE
    });
    // sq-logic
    dispatch({
      type: Actions.SET_QUOTE_MODIFIED,
      payload: true
    });
  };

  const processQuoteResponse = response => {
    if (response?.confirmationId) {
      fixCSR(response);
      dispatch({
        type: Actions.UPDATE_QUOTE,
        payload: response
      });
    }
  };

  // Closes the RO Details and shows the RO Grid.
  // Should work regardless of whether the RO Details is open in the
  // quote slider, or open as a separate standalone page.
  const closeRODetails = () => {
    if (window.location.pathname.includes("dashboard")) {
      // Close the slider.
      dispatch({
        type: Actions.SET_TOGGLE_MODAL,
        payload: true
      });
    } else if (window.location.pathname.includes("rodetails")) {
      // Navigate to the RO grid page.
      const params = new URLSearchParams(window.location.search);
      params.delete("quoteId");
      params.delete("roNumber");
      window.location.href = "/dashboard?" + params.toString();
    }
  };

  // @note: skip customer flow, when quote exists then update recent saved customer to quote.
  const updateCustomerInQuote = async customer => {
    try {
      const status = "IN_PROGRESS";
      // @note: read response when quote status updated in DB
      const response = await quoteService.patchQuote({
        appContext,
        quoteSummary,
        status,
        customer,
        vehicle
      });
      if (!isEmpty(response)) {
        processQuoteResponse(response);
      }
    } catch (error) {
      const msg = error["message"]
        ? error.message
        : localeStrings["sq.errors.network.error"];
      console.error(msg);
    }
  };

  const buildCheckInOperations = () => {
    const ops = [];
    if (quoteSummary.serviceWriter !== originalQuoteSummary.serviceWriter) {
      ops.push({
        op: "replace",
        path: "/serviceWriter",
        value: quoteSummary.serviceWriter,
        activityLogMessage: buildActivityLogMessage(
          "Advisor",
          originalQuoteSummary.serviceWriter,
          quoteSummary.serviceWriter,
          id => {
            const advisor = advisors.find(a => a.id === id);
            return advisor?.firstName + " " + advisor?.lastName;
          }
        ),
        activityLogCategory: "Repair order"
      });
    }
    if (quoteSummary.vehicle.vin !== originalQuoteSummary.vehicle.vin) {
      ops.push({
        op: "replace",
        path: "/vehicle/vin",
        value: quoteSummary.vehicle.vin,
        activityLogMessage: buildActivityLogMessage(
          "VIN",
          originalQuoteSummary.vehicle.vin,
          quoteSummary.vehicle.vin
        ),
        activityLogCategory: "Repair order"
      });
    }
    // Test hook to send the mileageIn to the API as "X", which should cause an actual API error.
    if (quoteSummary.hangTag.trim() === "TEST_HOOK_MILEAGE_IN_X") {
      ops.push({ op: "replace", path: "/mileageIn", value: "X" });
    } else if (quoteSummary.mileageIn !== originalQuoteSummary.mileageIn) {
      ops.push({
        op: "replace",
        path: "/mileageIn",
        value: quoteSummary.mileageIn,
        activityLogMessage: buildActivityLogMessage(
          "Mileage in",
          originalQuoteSummary.mileageIn,
          quoteSummary.mileageIn
        ),
        activityLogCategory: "Repair order"
      });
    }
    if (quoteSummary.hangTag !== originalQuoteSummary.hangTag) {
      ops.push({
        op: "replace",
        path: "/hangTag",
        value: quoteSummary.hangTag,
        activityLogMessage: buildActivityLogMessage(
          "Hang tag",
          originalQuoteSummary.hangTag,
          quoteSummary.hangTag
        ),
        activityLogCategory: "Repair order"
      });
    }
    ops.push({
      op: "replace",
      path: "/checkedInDateTime",
      value: moment().local().format("YYYY-MM-DDTHH:mm:ssZ"),
      activityLogMessage: "RO checked in",
      activityLogCategory: "Repair order"
    });
    ops.push({
      op: "replace",
      path: "/quoteStatus",
      value: RO_STATUSES.IN_PROCESS.value
    });
    return ops;
  };

  // @csr-logic
  const approveEstimate = async () => {
    setShowApproveEstimateModal(false);
    const ops = buildCheckInOperations();

    const response = await blockUntilCompleted(async () => {
      const innerResponse = await csrService.jsonPatchCSR({
        appContext,
        confirmationId,
        ops
      });
      if (!isEmpty(innerResponse)) {
        processQuoteResponse(innerResponse);
      }
      return innerResponse;
    });

    if (response?.roNumber) {
      toast.success(
        "Estimate approved. Documents are being generated and will open shortly."
      );
      if (quoteSummary?.roNumber === null) {
        quoteSummary.roNumber = response.roNumber;
      }
      await csrService.printSelectedDocuments(
        quoteSummary.dealerCode,
        quoteSummary.quoteId,
        ["serviceEstimate", "techHardCard"],
        quoteSummary
      );
    }
  };

  const searchArchive = async () => {
    await blockUntilCompleted(async () => {
      const documentsURL = await csrService.getDocumentsURL({
        dealerCode: appContext.dealer.dealerCode,
        csrnumber: isDmsPlusQuote
          ? archivedQuoteData.confirmationId
          : confirmationId
      });
      window.open(documentsURL);
    });
  };

  // @csr-logic
  const patchCSRStatus = async newStatus => {
    return await blockUntilCompleted(async () => {
      const response = await csrService.patchCSRStatus({
        appContext,
        quoteSummary,
        status: newStatus
      });
      if (!isEmpty(response)) {
        processQuoteResponse(response);
      }
      return response;
    });
  };

  /* checkbox change handler */
  const expandAllClick = () => {
    setShowDetailsSwitchValue(prev => !prev);
  };
  const showPageLoading = showPageMask => {
    dispatch({
      type: Actions.SET_PAGE_MASK,
      payload: showPageMask
    });
  };

  const removeServiceFromQuote = async deletedService => {
    try {
      showPageLoading(true);
      const response = await quoteService.removeServiceFromQuote({
        deletedService,
        quoteSummary
      });
      isCSR && fixCSR(response);
      let quoteResponse = null;
      if (!isEmpty(response)) {
        const { quoteId, confirmationId } = response;
        let message = "";
        if (!confirmationId && !quoteId) {
          message =
            "Services are not available for this vehicle at this time.  Please check back later.";
          quoteResponse = {
            quoteId: null,
            confirmationId: null,
            quoteServices: [],
            message
          };
        } else {
          if (isEmpty(response.quoteServices)) response.quoteServices = [];
          response.message = "";
          quoteResponse = Object.assign({}, response);
        }
        dispatch({
          type: Actions.UPDATE_QUOTE,
          payload: quoteResponse
        });
        showPageLoading(false);
        serviceCache.removeAddedService(
          confirmationId,
          deletedService.extServiceId
        );
      }
    } catch (error) {
      showPageLoading(false);
      const msg = error["message"]
        ? error.message
        : localeStrings["sq.errors.network.error"];
      console.error(msg);
    }
  };

  const showConfirmationModal = (mode, event) => {
    setShowConfirmation(true);
    setCurrentModeAndEvent({ mode, event });
  };

  const closeConfirmationModal = () => {
    setShowConfirmation(false);
  };

  /**
   Handles the update of a confirmation quote's status and performs related actions based on the current mode.
   This function updates the quote status in the database and triggers appropriate dispatch actions.
   It also handles different modes like 'add' to perform corresponding actions.
   @throws {Error} If there's an issue during the process, an error message is displayed.
   */
  const handleConfirmationQuoteUpdate = async () => {
    try {
      // Extract necessary data from the app context and current state
      const { dealer, user } = appContext;
      const { dealerCode } = dealer;
      const { confirmationId } = quoteSummary;
      const { mode } = currentModeAndEvent;
      const status = QuoteStatusConstant.PROGRESS;

      // Handle different modes: 'add'
      if (mode === "add") {
        gotoAddServices(currentModeAndEvent.event);
      }

      // @note: read response when quote status updated in DB
      const response = await quoteService.updateQuoteStatus({
        dealerCode,
        confirmationId,
        lastModByUserId: user.quoteUserId,
        quoteStatus: status
      });

      // If response is valid, dispatch appropriate actions for quote update and status change
      if (!isEmpty(response)) {
        const { confirmationId } = response;
        if (!isEmpty(confirmationId)) {
          dispatch({
            type: Actions.UPDATE_QUOTE,
            payload: response
          });
        }
      }
    } catch (error) {
      // Handle errors and display appropriate message
      const msg = error["message"]
        ? error.message
        : localeStrings["sq.errors.network.error"];
      if (
        error.hasOwnProperty("response") &&
        error["response"].status === 422
      ) {
        toast.error(
          "This quote has already been converted to an appointment, so it cannot be edited.  Please refresh the page to see the current quote status.",
          {
            closeOnClick: true
          }
        );
      }
      console.error(msg);
    }
  };

  const handleDragEnd = event => {
    const { active, over } = event;
    if (!active.id !== over.id) {
      setServiceList(serviceList => {
        const oldIndex = serviceList.findIndex(
          service => service.id === active.id
        );

        const newIndex = serviceList.findIndex(
          service => service.id === over.id
        );
        serviceList[oldIndex].displayOrder = null;
        const shuffledServices = arrayMove(serviceList, oldIndex, newIndex);

        const updatedServices = getUpdatedServicesWithDisplayOrder(
          shuffledServices,
          true
        );
        if (updatedServices.length) {
          const { confirmationId } = quoteSummary;
          updateDisplayOrderForServices(confirmationId, updatedServices);
        }
        quoteSummary.quoteServices = shuffledServices;
        return arrayMove(serviceList, oldIndex, newIndex);
      });
    }
  };
  /**
   * Opens comeback modal sending service information
   * @param {string} serviceId
   * @param {string | null} overridePrevRONumber
   * @param {string | null} prevRONumber
   */
  const openComebackModal = ({
    serviceId,
    overridePrevRONumber,
    prevRONumber
  }) => {
    comebackModalRef.current?.openConfirmationModal({
      serviceId,
      overridePrevRONumber,
      prevRONumber
    });
  };
  /**
   * Saves comeback selection
   * @param {string | null} roId
   * @param {string} serviceId
   */
  const saveComeBack = async (roId, serviceId) => {
    const { confirmationId } = quoteSummary;
    const response = await blockUntilCompleted(async () => {
      const serviceFound = quoteSummary.quoteServices.find(service => {
        return service.extServiceId === serviceId;
      });
      return await updateOverridePrevRoNumber(confirmationId, {
        ...serviceFound,
        overridePrevRONumber: roId
      });
    });
    dispatch({
      type: Actions.UPDATE_QUOTE,
      payload: response
    });
  };
  /**
   * Creates a Pre-RO based on the previously selected services.
   * @return {Promise<void>}
   */
  const startCreatingPreRo = async reason => {
    const { confirmationId } = quoteSummary;
    const response = await blockUntilCompleted(async () => {
      const originalRo = await createPreRo(
        confirmationId,
        preRoServiceList,
        reason
      );
      dispatch({
        type: Actions.SET_PRE_RO_SERVICE_LIST,
        payload: []
      });
      return originalRo;
    });
    dispatch({
      type: Actions.UPDATE_QUOTE,
      payload: response
    });
  };
  /**
   * Cancels the Pre-RO process by restoring the Quote to its original state
   * @return {Promise<void>}
   */
  const cancelPreRo = async () => {
    try {
      toggleLoadingMask(true);
      await reloadQuote();
      dispatch({
        type: Actions.SET_PRE_RO_SERVICE_LIST,
        payload: []
      });
    } catch (error) {
      const msg = !error ? "Error occurred while updating data" : error.message;
      toast.error(msg, {
        closeOnClick: true
      });
    } finally {
      toggleLoadingMask(false);
    }
  };
  /**
   * Opens a new tab that navigates to the scheduling portal for creating a new appointment using the confirmationId.
   * @return {Promise<void>}
   */
  const createApptFromPreRo = async () => {
    const { confirmationId } = quoteSummary;
    const { dealer, loginURL } = appContext;
    const { webKey } = dealer;
    const scheduleAppURL = `${loginURL}/?w=${webKey}&act=mpra&roc=${confirmationId}`;
    updateOpenerWindow(scheduleAppURL, "_Scheduling");
  };
  // * this function is made for ordering parts, primarily for core parts(primary, core part, core return part)
  const formatPartsInOrder = parts => {
    let consolidatedParts = [];
    parts.map(servicePart => {
      const coreParts = parts?.filter(
        part =>
          !consolidatedParts.some(
            p => p.quoteServicePartId === part.quoteServicePartId
          ) &&
          !servicePart?.isCorePart &&
          part?.isCorePart &&
          part?.oemPartNumber?.slice(0, -2) === servicePart?.oemPartNumber
      );

      if (coreParts?.length > 0) {
        const sortedCoreParts = sortByLastTwoCharacters(coreParts);
        consolidatedParts = [
          ...consolidatedParts,
          servicePart,
          ...sortedCoreParts
        ];
      } else {
        if (!servicePart?.isCorePart) {
          consolidatedParts = [...consolidatedParts, servicePart];
        }
      }
    });
    return consolidatedParts;
  };

  const getServiceDetails = (services, isShadowService) => {
    const serviceDetails = services.map((service, index) => {
      // Initialize flag to check if a technician is assigned to the service.
      let isTechAssignedToService = false;
      const menuServices = service?.menuServices || [];

      // Check if the service type is MENU to handle menu services specifically.
      if (service.quoteServiceType === QuoteServiceTypes.MENU) {
        // Iterate over menu services to find if a technician is assigned.
        for (const menuService of menuServices) {
          isTechAssignedToService = menuService?.technicians?.some(
            tech => tech?.techUser?.extUserId === user?.userId
          );
          // Break the loop early if a technician is found.
          if (isTechAssignedToService) break;
        }
      } else {
        // For non-menu services, check directly in the service's technicians.
        isTechAssignedToService = service?.technicians?.some(
          tech => tech?.techUser?.extUserId === user?.userId
        );
      }
      // If flag is false, permission is false and technician is not assigned to current service, return null.
      if (
        !isTechnicianSeeAllTechServicesEnabled &&
        !userPermissions?.canViewAllROServices &&
        !isTechAssignedToService
      ) {
        return null;
      }
      // F216377: If the service was already selected as Pre-RO, it should not be displayed in the service list.
      if (preRoServiceList.includes(service.quoteServiceId)) {
        return null;
      }

      // Set the service ID from quoteServiceId for use in drag and drop functionality.
      service.id = service.quoteServiceId; // added for drag and drop functionality as library takes id as key for identification

      // Define a unique key for the service using quoteServiceId or index if unavailable.
      const serviceKey = service.quoteServiceId
        ? service?.quoteServiceId.toString()
        : index;

      // Format parts array into a specific order.
      service.parts = formatPartsInOrder(get(service, "parts", []));

      return (
        <ServiceDetails
          key={`key-service-${serviceKey}`}
          expandDetails={showDetailsSwitchValue}
          service={service}
          menuOptions={service.menuOptions}
          removeService={() => removeServiceFromQuote(service)}
          onOpenComebackModal={openComebackModal}
          onMoveServiceToPreRo={handleMoveServiceToPreRo}
          isShadowService={isShadowService}
        />
      );
    });

    return serviceDetails;
  };

  const handleMoveServiceToPreRo = async preROService => {
    try {
      showPageLoading(true);
      const updatedPreRoServiceList = [...preRoServiceList, preROService.id];
      const updatedServiceList = serviceList.filter(
        service => service.id !== preROService.id
      );
      const recalculatedTotals =
        await csrService.calculateNewTotalWithoutDeferredServices({
          dealerCode: appContext.dealer?.dealerCode,
          confirmationId,
          preRoServiceIds: updatedPreRoServiceList
        });
      dispatch({
        type: Actions.SET_PRE_RO_SERVICE_LIST,
        payload: updatedPreRoServiceList
      });
      dispatch({
        type: Actions.UPDATE_QUOTE,
        payload: {
          ...quoteSummary,
          payers: recalculatedTotals
        }
      });
    } catch (error) {
      console.log(`Error moving service to Pre-RO list: ${error}`);
    } finally {
      showPageLoading(false);
    }
  };

  const handleAddToPurchaseOrder = () => {
    loadEmergencyParts(appContext, quoteSummary.roNumber);
    loadPurchaseOrders();
    setShowAddPurchaseModal(true);
  };

  const onCloseAddPurchaseModal = () => {
    setShowAddPurchaseModal(false);
    setShowEmergencyPartError(false);
    setShowCreatePoError(false);
  };

  const loadEmergencyParts = async (context, roNumber) => {
    if (isEmergencyPartsEnabled) {
      try {
        const response = await quoteService.getEmergencyParts(
          context,
          roNumber
        );
        setEmergencyParts(response.items);
      } catch (error) {
        const msg = error["message"]
          ? error.message
          : localeStrings["sq.errors.network.error"];
        console.error(msg);
      }
    }
  };
  const loadPurchaseOrders = async () => {
    if (isEmergencyPartsEnabled) {
      try {
        const response = await quoteService.getPurchaseOrderList({
          appContext
        });
        setPurchaseOrdersList(response.items);
      } catch (error) {
        const msg = error["message"]
          ? error.message
          : localeStrings["sq.errors.network.error"];
        console.error(msg);
      }
    }
  };

  const showNetworkError = error => {
    const msg = error["message"]
      ? error.message
      : localeStrings["sq.errors.network.error"];
    setShowEmergencyPartError(true);
    console.error(msg);
  };
  const getEmergencyPartsBuyers = async () => {
    const dealerCode = appContext?.dealer?.dealerCode;
    try {
      const response = await quoteService.getEmergencyPartsBuyers(dealerCode);
      return response?.items;
    } catch (err) {
      showNetworkError(err);
    }
  };

  const getEmergencyPartsManufacturers = async searchText => {
    const dealerCode = appContext?.dealer?.dealerCode;
    try {
      const response = await quoteService.getEmergencyPartsManufacturers(
        dealerCode,
        searchText
      );
      return response?.items;
    } catch (err) {
      showNetworkError(err);
    }
  };

  const handleSaveToPurchaseOrder = async (
    selectedParts,
    selectedPurchaseOrder
  ) => {
    try {
      const response = await quoteService.updatePurchaseOrder({
        dealerCode: appContext.dealer.dealerCode,
        quoteSummary,
        selectedParts,
        selectedPurchaseOrder
      });
      onCloseAddPurchaseModal(false);
      setShowEmergencyPartError(false);
      const partsString = selectedParts?.length > 1 ? " part(s)" : " part";
      toast.success(
        selectedParts?.length +
          partsString +
          " added to " +
          selectedPurchaseOrder?.label
      );
      setTimeout(() => {
        reloadQuote();
      }, 3000);
    } catch (error) {
      showNetworkError(error);
    }
  };
  const handleSaveNewPurchaseOrder = async (selectedParts, purchaseOrder) => {
    try {
      const response = await quoteService.createPurchaseOrder({
        dealerCode: appContext.dealer.dealerCode,
        quoteSummary,
        selectedParts,
        purchaseOrder
      });
      onCloseAddPurchaseModal(false);
      setShowEmergencyPartError(false);
      const partsString = selectedParts?.length > 1 ? " part(s)" : " part";
      toast.success(
        "A new purchase order has been created for " +
          selectedParts?.length +
          partsString
      );
      setShowCreatePoError(false);
      setTimeout(() => {
        reloadQuote();
      }, 3000);
    } catch (error) {
      setShowCreatePoError(true);
      showNetworkError(error);
    }
  };
  const isEmergencyPartsAvailable = () => {
    if (quoteServices?.length > 0) {
      const emergencyParts = quoteServices.flatMap(part => part.parts);
      const partList = emergencyParts.filter(part => {
        return part.purchaseType === "EP/Drop" && !part.purchaseOrderNo;
      });
      return partList.length !== 0;
    }
    return false;
  };

  const emergencyPartsGrid = (
    <div className="parts-preview-grid">
      <EmergencyPartsGrid
        parts={emergencyParts}
        purchaseOrders={purchaseOrdersList}
        handleSaveToPurchaseOrder={handleSaveToPurchaseOrder}
        handleSaveNewPurchaseOrder={handleSaveNewPurchaseOrder}
        showEmergencyPartError={showEmergencyPartError}
        showCreatePoError={showCreatePoError}
        isCreatePoEnabled={appContext.isCreatePoEnabled}
        getEmergencyPartsBuyers={getEmergencyPartsBuyers}
        getEmergencyPartsManufacturers={getEmergencyPartsManufacturers}
      />
    </div>
  );
  const addToPurchaseOrderModal = (
    <ModalDialog
      htmlId="addToPurchaseOrderModal"
      className="parts-lookup-modal"
      show={showAddPurchaseModal}
      backdrop="static"
      header={<h4>Add Part(s) to a Purchase Order.</h4>}
      onHide={onCloseAddPurchaseModal}
    >
      {emergencyPartsGrid}
    </ModalDialog>
  );
  const preROReasonModal = (
    <ReasonModal
      title="Move to Pre-RO?"
      message={`Please provide a reason for moving ${
        preRoServiceList?.length === 1 ? "this service" : "these services"
      }`}
      submitButtonText="Move"
      showModal={showPreROReasonModal}
      onCancel={() => setShowPreROReasonModal(false)}
      onSubmit={reason => {
        setShowPreROReasonModal(false);
        startCreatingPreRo(reason);
      }}
    />
  );
  const userPermissionForEditQuoteService =
    userPermissions.canUpdateQuoteService;

  const isQuoteCreated = !confirmationId || !quoteId ? false : true;
  let results = null;

  // @note: When RO opened from Engage, Service History button and sticky footer are not rendered.
  const isEngageSource = appSource === appSources.ENG;
  // @note: "WITH_ADVISOR" RO status is not included in this constant.
  const isCheckedInRO = [
    roStatusOptions.IN_PROCESS,
    roStatusOptions.WORK_FINISHED,
    roStatusOptions.PRE_INVOICE,
    roStatusOptions.FINALIZED
  ].includes(quoteSummary?.quoteStatus);

  if (isQuoteCreated && !isEmpty(quoteServices)) {
    // @sq-logic
    const editQuotePermission = hasEditQuoteAccessNew(
      quoteSummary,
      userPermissions
    );
    const componentHeader = (
      <div className="component-header-quote padding-bottom-14">
        {isCSR ? (
          <div className="quote-summary-header">
            {!isPartsView ? (
              <div className="xmm-checkbox-container" id="expandAllCSR">
                <ToggleSwitch
                  htmlId="ShowDetailsToggleCSR"
                  defaultChecked
                  name="expandAll"
                  checked={showDetailsSwitchValue}
                  onClick={expandAllClick}
                />
                <span className="label-checkbox full-label">
                  {localeStrings["sq.newquote.summary.show_details_lbl"]}
                </span>
              </div>
            ) : null}

            <div className="action-box">
              {/* <Button
                className="quote-summary-action"
                size="sm"
                buttonStyle="secondary"
                htmlId="documentsButton"
              >
                OEM Vehicle Info
              </Button> */}
              <ComebackModalDialog
                ref={comebackModalRef}
                vin={vehicle.vin}
                onSave={saveComeBack}
              />
              <ServiceContractModal
                show={showModal}
                cancelHandler={() => setShowModal(false)}
              />
              {!isEngageSource ? (
                <>
                  {isPartsView && userPermissions.canAddToPurchaseOrder ? (
                    <Button
                      className={
                        isEmergencyPartsEnabled
                          ? "quote-summary-action"
                          : "hide"
                      }
                      size="sm"
                      buttonStyle="secondary"
                      htmlId="addToPurchaseOrder"
                      onClick={() => handleAddToPurchaseOrder()}
                      disabled={!isEmergencyPartsAvailable()}
                      title={
                        !isEmergencyPartsAvailable()
                          ? "All parts are in stock or already on a purchase order"
                          : ""
                      }
                    >
                      Add to purchase order
                    </Button>
                  ) : (
                    <Button
                      className="quote-summary-action"
                      htmlId="serviceContractsButton"
                      size="sm"
                      buttonStyle="secondary"
                      onClick={() => {
                        setShowModal(true);
                      }}
                    >
                      Service contracts
                    </Button>
                  )}
                </>
              ) : null}
              {isPartsView && userPermissions.canPrintPickList ? (
                <PrintPickListComponent />
              ) : null}
              {!isEngageSource ? (
                <Button
                  className="quote-summary-action"
                  size="sm"
                  buttonStyle="secondary"
                  htmlId="serviceHistoryButton"
                  onClick={() => {
                    gtmEvent.trackGAPageview("/serviceHistory");
                    openNewWindow(
                      "/servicehistory",
                      vehicle.vin,
                      null,
                      null,
                      0,
                      0,
                      900,
                      600,
                      `${vehicle?.year} ${vehicle?.make} ${vehicle?.model} - ${vehicle?.vin} - Service History`
                    );
                  }}
                  disabled={!hasVIN}
                  title={hasVIN ? "" : "Not available due to missing VIN"}
                >
                  Service history
                </Button>
              ) : null}
              {userPermissions.canUpdateFeesDiscountsSublets &&
              !isPartsView &&
              !(isEngageSource && isCheckedInRO) ? (
                <>
                  {isCSR ? (
                    <>
                      <Button
                        className="quote-summary-action"
                        htmlId="catalogRoLevelDiscount"
                        size="sm"
                        buttonStyle="secondary"
                        onClick={() => {
                          dispatch({
                            type: Actions.SET_PRICE_ADJUSTMENT_TYPE,
                            payload: ADD_CATALOG_RO_LEVEL_DISCOUNT
                          });
                        }}
                        disabled={isFinalized}
                        title={
                          isFinalized ? "Not available for finalized ROs" : ""
                        }
                      >
                        Discounts
                      </Button>
                      <Button
                        className="quote-summary-action"
                        size="sm"
                        buttonStyle="secondary"
                        htmlId="roFeesButton"
                        onClick={() => {
                          dispatch({
                            type: Actions.SET_PRICE_ADJUSTMENT_TYPE,
                            payload: ADD_CATALOG_RO_FEE
                          });
                        }}
                        disabled={isFinalized}
                        title={
                          isFinalized ? "Not available for finalized ROs" : ""
                        }
                      >
                        Fees
                      </Button>
                    </>
                  ) : null}
                  <Button
                    className="quote-summary-action"
                    size="sm"
                    htmlId="addServiceButton"
                    buttonStyle="secondary"
                    onClick={e => gotoAddServices(e)}
                    disabled={
                      !userPermissionForEditQuoteService ||
                      isFinalized ||
                      hasClosedPayers
                    }
                    title={
                      isFinalized
                        ? "Not available for finalized ROs"
                        : hasClosedPayers
                        ? "Cannot add services after closing any payers"
                        : ""
                    }
                  >
                    Services
                  </Button>
                </>
              ) : null}
            </div>
          </div>
        ) : null}
        {!isCSR ? (
          <div className="xmm-checkbox-container" id="expandAll">
            <ToggleSwitch
              htmlId="ShowDetailsToggle"
              defaultChecked
              name="expandAll"
              checked={showDetailsSwitchValue}
              onClick={expandAllClick}
            />
            <span className="label-checkbox full-label">
              {localeStrings["sq.newquote.summary.show_details_lbl"]}
            </span>
          </div>
        ) : null}
        {!isCSR ? (
          <div className="action-box">
            <div className={`${themePrefixer()}btn-group`}>
              <Button
                buttonStyle="secondary"
                htmlId="addServicesBtn"
                size="small"
                onClick={
                  isSent
                    ? e => showConfirmationModal("add", e)
                    : e => gotoAddServices(e)
                }
                disabled={
                  !userPermissionForEditQuoteService || !editQuotePermission
                }
              >
                {localeStrings["sq.newquote.customer.add_services_lbl"]}
              </Button>
              <Button
                aria-label="Down"
                size="small"
                buttonStyle="secondary"
                htmlId="addServicesIcon"
                icon={<IconKeyboardArrowDown htmlId="icondown" />}
                disabled={!userPermissionForEditQuoteService}
              />
            </div>
          </div>
        ) : null}
      </div>
    );

    const serviceDetails = !isEmpty(serviceList)
      ? getServiceDetails(serviceList)
      : null;
    const shadowServiceDetails = !isEmpty(shadowServices)
      ? getServiceDetails(shadowServices, true)
      : null;
    results = (
      <div className={`quote-services-box ${isPartsView ? "parts-view" : ""}`}>
        {componentHeader}
        <div className="op-table sq-alacarte-table">
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={serviceList}
              strategy={verticalListSortingStrategy}
            >
              {serviceDetails}
              {shadowServiceDetails}
            </SortableContext>
          </DndContext>
        </div>
      </div>
    );
  } else {
    results = (
      <div className="quote-no-services-box">
        <div className="sq-no-results">
          <b>{localeStrings["sq.newquote.summary.no_services_lbl"]}</b>
        </div>
        <span className="plain-label">
          To get started, add services to this {isCSR ? "RO" : "quote"}
        </span>
        <Button
          buttonStyle="primary"
          htmlId="addServicesBtn"
          onClick={
            !isCSR && isSent
              ? e => showConfirmationModal("add", e)
              : e => gotoAddServices(e)
          }
          disabled={!userPermissionForEditQuoteService}
        >
          {localeStrings["sq.newquote.customer.add_services_lbl"]}
        </Button>
      </div>
    );
  }
  // @csr-logic
  const getCSRProceedButtonState = () => {
    const defaultState = {
      displayText: "Proceed",
      isDisabled: true,
      onClick: () => {}
    };
    if (!quoteSummary || isEmpty(quoteServices)) {
      return defaultState;
    }
    const { mileageIn, hangTag, serviceWriter } = quoteSummary;
    switch (quoteSummary.quoteStatus) {
      case RO_STATUSES.PRE_RO.value:
        return {
          displayText: "Schedule Appointment",
          isDisabled: false,
          onClick: createApptFromPreRo
        };
      case RO_STATUSES.WITH_ADVISOR.value:
        return {
          displayText: "Approve estimate",
          isDisabled:
            !mileageIn ||
            mileageIn < 1 ||
            !hangTag ||
            vehicle?.vin?.length !== 17 ||
            !serviceWriter ||
            (hasPayerWithoutServiceContract(quoteSummary) &&
              !isDMSPlusDealerWithOtherPayer) ||
            isPromisedTimeBeforeCheckInDateTime(quoteSummary) ||
            (isUseOEMWarrantyEnabled &&
              !quoteServiceTypes.includes(QuoteServiceTypes.RECALL) &&
              hasWarrantyPayerWithWarrantyPlanWithoutApprovalCode(
                quoteSummary
              )),
          onClick: () => {
            setShowApproveEstimateModal(true);
          },
          tooltipText: isPromisedTimeBeforeCheckInDateTime(quoteSummary)
            ? "Promised date time cannot be before checkIn date time."
            : null
        };
      case RO_STATUSES.IN_PROCESS.value:
      case RO_STATUSES.WORK_FINISHED.value:
        return {
          displayText: "Start invoicing",
          isDisabled:
            quoteServices.some(s => !s.completedTime) ||
            (hasPayerWithoutServiceContract(quoteSummary) &&
              !isDMSPlusDealerWithOtherPayer) ||
            (isUseOEMWarrantyEnabled &&
              !quoteServiceTypes.includes(QuoteServiceTypes.RECALL) &&
              hasWarrantyPayerWithWarrantyPlanWithoutApprovalCode(
                quoteSummary
              )),
          onClick: () => {
            patchCSRStatus(RO_STATUSES.PRE_INVOICE.value);
          }
        };
      case RO_STATUSES.PRE_INVOICE.value:
        return {
          displayText: "Finalize RO",
          isDisabled:
            quoteServices.some(s => s.paymentStatus !== "ready") ||
            (hasPayerWithoutServiceContract(quoteSummary) &&
              !isDMSPlusDealerWithOtherPayer) ||
            (isUseOEMWarrantyEnabled &&
              !quoteServiceTypes.includes(QuoteServiceTypes.RECALL) &&
              hasWarrantyPayerWithWarrantyPlanWithoutApprovalCode(
                quoteSummary
              )),
          onClick: () => setShowFinalizeROModal(true)
        };
      case RO_STATUSES.FINALIZED.value:
        return {
          displayText: "Done",
          isDisabled:
            (hasPayerWithoutServiceContract(quoteSummary) &&
              !isDMSPlusDealerWithOtherPayer) ||
            (isUseOEMWarrantyEnabled &&
              !quoteServiceTypes.includes(QuoteServiceTypes.RECALL) &&
              hasWarrantyPayerWithWarrantyPlanWithoutApprovalCode(
                quoteSummary
              )),
          onClick: closeRODetails
        };
      default:
        return defaultState;
    }
  };
  const csrProceedButtonState = getCSRProceedButtonState();

  const reloadQuote = async () => {
    const updatedQuote = await loadQuote({
      confirmationId: quoteSummary.confirmationId,
      localeStrings: appContext.localeStrings,
      dealerCode: appContext.dealer.dealerCode,
      appContext
    });
    dispatch({
      type: Actions.UPDATE_QUOTE,
      payload: updatedQuote
    });
    return updatedQuote;
  };

  const toggleLoadingMask = show => {
    dispatch({
      type: Actions.SET_PAGE_MASK,
      payload: show
    });
  };

  // Updates the mileageOut if it changed.
  const updateMileageOut = async mileageOut => {
    if (mileageOut !== quoteSummary.mileageOut) {
      quoteSummary.mileageOut = mileageOut;

      // Wrapping this call in try-catch since a failure to update
      // mileageOut shouldn't halt the rest of this workflow.
      try {
        await quoteService.updateQuoteMileageOut({
          dealerCode: quoteSummary.dealerCode,
          confirmationId: quoteSummary.confirmationId,
          lastModByUserId: appContext.user.quoteUserId,
          mileageOut
        });
      } catch (err) {
        console.error(err);
      }
    }
  };

  // Updates the paymentLines if it changed.
  const updatePaymentLines = async (diffOps, isDelete) => {
    if (diffOps?.ops?.length > 0) {
      try {
        await quoteService.updatePaymentLinesUsingJsonPatch({
          dealerCode: quoteSummary.dealerCode,
          confirmationId: quoteSummary.confirmationId,
          payerId: diffOps?.payerId,
          diffOps: diffOps?.ops
        });
      } catch (err) {
        console.error(err);
      }
    }
    if (isDelete) {
      await reloadQuote();
    }
  };

  const triggerCreditCardPaymentWorkflow = customerCreditCardPayer => {
    window.parent.postMessage(
      {
        action: "CSR_PAYMENT",
        appointmentCode: quoteSummary.appointmentCode,
        amount: customerCreditCardPayer.finalPrice,
        currency: "USD",
        confirmationId: quoteSummary.confirmationId,
        roNumber: quoteSummary.roNumber,
        dealerCode: quoteSummary.dealerCode,
        payerId: customerCreditCardPayer.payerId
      },
      window.location.ancestorOrigins[0]
    );
  };

  const updatePayers = async payersToUpdate => {
    const closePayersPayload = payersToUpdate
      .filter(p => p.paymentStatus === "closed")
      .map(payer => {
        return {
          payerId: payer.payerId,
          lastModBy: appContext.user.quoteUserId,
          closedDate: payer.closedDate,
          paymentMethod: payer.paymentMethod,
          paymentStatus: payer.paymentStatus,
          payments: payer.payments
        };
      });

    // Wrapping this call in try-catch because Credit Card payment will
    // return an API error response even though the call was successful.
    try {
      await editQuoteService.closePayers(
        quoteSummary.dealerCode,
        quoteSummary.quoteId,
        closePayersPayload
      );
    } catch (err) {
      console.error(err);
    }

    const updatedQuote = await reloadQuote();
    const customerCreditCardPayer = payersToUpdate.find(
      payer =>
        payer.payType === "C" &&
        payer.paymentMethod === PAYMENT_TYPES.CREDIT_CARD
    );
    if (customerCreditCardPayer && !isSplitPaymentEnabled) {
      triggerCreditCardPaymentWorkflow(customerCreditCardPayer);
    } else {
      showClosePayerResults(payersToUpdate, updatedQuote);
    }
  };

  const showClosePayerResults = (submittedPayers, updatedQuote) => {
    const submittedPayersAfterUpdate = submittedPayers.map(before => {
      return updatedQuote.payers.find(
        after => after.payerId === before.payerId
      );
    });
    const failedPayers = submittedPayersAfterUpdate.filter(p =>
      isEmpty(p.closedDate)
    );
    if (failedPayers.length) {
      failedPayers.forEach(payer => {
        const payTypeName = payer.payTypeGroup.replace(/pay$/i, "");
        toast.error(`Unable to close the ${payTypeName} pay type.`);
      });
    }
    const successfulPayers = submittedPayersAfterUpdate.filter(
      p => !isEmpty(p.closedDate)
    );
    setUpdatedPayers(successfulPayers);
    if (successfulPayers.length) {
      setShowFinalizeROConfirmationModal(true);
    }
  };

  // @csr-logic
  const processFinalizeROModalPayload = async modalPayload => {
    const { mileageOut, payersToUpdate, diffOps } = modalPayload;

    try {
      setShowFinalizeROModal(false);
      toggleLoadingMask(true);

      // This test hook provides a way to call the closePayer API for a
      // customer cash payment without including the required drawer ID,
      // which should trigger an actual backend request validation error.
      if (document.location.href.includes("TEST_HOOK_MISSING_CASH_DRAWER=1")) {
        const pendingCashPayments = payersToUpdate.flatMap(payer => {
          return payer.payments?.filter(
            payment => payment.paymentMethod === PAYMENT_TYPES.CASH
          );
        });
        pendingCashPayments.forEach(payment => {
          payment.drawerNumber = null;
        });
      }

      await updateMileageOut(mileageOut);
      await updatePaymentLines(diffOps, false);
      await updatePayers(payersToUpdate);
    } catch (e) {
      console.log("Error", e);
    } finally {
      toggleLoadingMask(false);
    }
  };

  const closeFinalizeRoModal = async diffOps => {
    setShowFinalizeROModal(false);
    if (diffOps?.ops?.length > 0 && diffOps !== null)
      try {
        toggleLoadingMask(true);
        await updatePaymentLines(diffOps, false);
        await reloadQuote();
        toggleLoadingMask(false);
      } catch (e) {
        console.log("Error", e);
        toggleLoadingMask(false);
      }
  };

  const roPayTypes = [
    ...new Set(quoteSummary?.quoteServices?.map(s => s.payTypeCode))
  ];

  const openDocumentsModal = () => {
    setShowFinalizeROConfirmationModal(false);
    if (hasRONumber) {
      setShowDocumentsModal(true);
    }
  };

  const requestServiceaAssistance =
    localeStrings["sq.newquote.summary.requestServiceaAssistance.alert_lbl"] +
    confirmationId;
  const completeServiceAssistance =
    localeStrings["sq.newquote.summary.completeServiceAssistance.alert_lbl"] +
    confirmationId;
  const requestPartsAssistance =
    localeStrings["sq.newquote.summary.requestPartsAssistance.alert_lbl"] +
    confirmationId;
  const completePartsAssistance =
    localeStrings["sq.newquote.summary.completePartsAssistance.alert_lbl"] +
    confirmationId;
  const requestedFail =
    localeStrings["sq.newquote.summary.requestedFail.alert_lbl"];
  const resolvingFail =
    localeStrings["sq.newquote.summary.resolvingFail.alert_lbl"];
  const sendQuoteToCustomer =
    localeStrings["sq.newquote.summary.sendQuoteToCustomer.alert_lbl"];

  const requestedFailAlert = (
    <AlertBox
      htmlId="requestedFailAlertBanner"
      displayCloseButton
      type="danger"
      message={requestedFail}
      loading={false}
      onClose={() =>
        dispatch({
          type: Actions.SET_REQUEST_STATUS,
          payload: {
            requestStatus: false,
            type: "requested_fail"
          }
        })
      }
    />
  );

  const resolvingFailAlert = (
    <AlertBox
      htmlId="resolvingFailAlertBanner"
      displayCloseButton
      type="danger"
      message={resolvingFail}
      loading={false}
      onClose={() =>
        dispatch({
          type: Actions.SET_REQUEST_STATUS,
          payload: {
            requestStatus: false,
            type: "completed_fail"
          }
        })
      }
    />
  );

  const handleCloseAlert = alertSource => {
    switch (alertSource) {
      case "sendQuoteBanner":
        dispatch({
          type: Actions.SET_SEND_MAIL_TO_CUSTOMER,
          payload: {
            MailToCustomerSent: false
          }
        });
        break;

      case "serviceAlertBanner":
        dispatch({
          type: Actions.SET_REQUEST_STATUS,
          payload: {
            requestStatus: false,
            type: "service"
          }
        });
        break;
      case "completeServiceAlertBanner":
        dispatch({
          type: Actions.SET_REQUEST_STATUS,
          payload: {
            requestStatus: false,
            type: "service",
            complete: true
          }
        });
        break;
      case "partsAlertBanner":
        dispatch({
          type: Actions.SET_REQUEST_STATUS,
          payload: {
            requestStatus: false,
            type: "parts"
          }
        });
        break;
      case "completePartsAlertBanner":
        dispatch({
          type: Actions.SET_REQUEST_STATUS,
          payload: {
            requestStatus: false,
            type: "service",
            complete: true
          }
        });
        break;
    }
  };

  const serviceAlert = (
    <AlertBox
      htmlId="serviceAlertBanner"
      displayCloseButton
      type="success"
      closeButton={true}
      message={requestServiceaAssistance}
      loading={false}
      onClose={() => handleCloseAlert("serviceAlertBanner")}
    />
  );

  const completeServiceAlert = (
    <AlertBox
      htmlId="completeServiceAlertBanner"
      displayCloseButton
      type="success"
      closeButton={true}
      message={completeServiceAssistance}
      loading={false}
      onClose={() => handleCloseAlert("completeServiceAlertBanner")}
    />
  );

  const partsAlert = (
    <AlertBox
      htmlId="partsAlertBanner"
      displayCloseButton
      type="success"
      closeButton={true}
      message={requestPartsAssistance}
      loading={false}
      onClose={() => handleCloseAlert("partsAlertBanner")}
    />
  );

  const completePartsAlert = (
    <AlertBox
      htmlId="completePartsAlertBanner"
      displayCloseButton
      type="success"
      closeButton={true}
      message={completePartsAssistance}
      loading={false}
      onClose={() => handleCloseAlert("completePartsAlertBanner")}
    />
  );

  const sendAlert = (
    <AlertBox
      htmlId="sendQuoteBanner"
      displayCloseButton
      type="success"
      closeButton={true}
      message={sendQuoteToCustomer}
      loading={false}
      onClose={() => handleCloseAlert("sendQuoteBanner")}
    />
  );

  const serviceContract = (
    <AlertBox
      htmlId="sendQuoteBanner"
      displayCloseButton
      type="warning"
      closeButton={false}
      message="Authorization is required."
      loading={false}
      onClose={() => handleCloseAlert("sendQuoteBanner")}
    />
  );

  const roPricingChangedBanner = (
    <AlertBox
      htmlId="ROPricingChangedBanner"
      displayCloseButton
      type="warning"
      closeButton={true}
      message="RO pricing details have changed. Authorization is required to close the Service Contract pay type."
      loading={false}
      onClose={dismmissROPricingBanner}
    />
  );

  return (
    <>
      {showServiceBanner ? serviceAlert : null}
      {showCompleteServiceBanner ? completeServiceAlert : null}
      {showPartsAlert ? partsAlert : null}
      {showCompletePartsBanner ? completePartsAlert : null}
      {showRequestedFailAlert ? requestedFailAlert : null}
      {showResolvingFailAlert ? resolvingFailAlert : null}
      {showMailBanner ? sendAlert : null}
      {addToPurchaseOrderModal}
      {preROReasonModal}
      {isCSR &&
      isUseOEMWarrantyEnabled &&
      roPayTypes.includes(PAYER_TYPES.W) &&
      !quoteServiceTypes.includes(QuoteServiceTypes.RECALL) &&
      !hasWarrantyApprovalCode &&
      !isPartsView
        ? serviceContract
        : null}
      {isCSR &&
      roPayTypes.includes(PAYER_TYPES.S) &&
      !hasServiceContract &&
      !isDMSPlusDealerWithOtherPayer &&
      !isPartsView
        ? serviceContract
        : null}
      {isCSR &&
      roPayTypes.includes(PAYER_TYPES.S) &&
      showROPricingBanner &&
      !isPartsView
        ? roPricingChangedBanner
        : null}
      {!isCSR ? (
        <ConfirmPopup
          title={localeStrings["sq.common.alert_lbl"]}
          message={
            localeStrings[
              "sq.newquote.location_bar.sent_quote-status-restart_modal_message"
            ]
          }
          show={showConfirmation}
          okText={localeStrings["sq.common.proceed_button"]}
          cancelText={localeStrings["sq.common.cancel_button"]}
          okAction={handleConfirmationQuoteUpdate}
          cancelAction={closeConfirmationModal}
          buttonStyle="danger"
        />
      ) : null}
      {!isCSR ? <QuoteStatusHeader localeStrings={localeStrings} /> : null}
      {canUseSpecialOrderParts(appContext) ? (
        <SpecialPartsBannerContainer containerClassName="service-special-parts-container" />
      ) : null}
      <div className="quote-service-main">
        {results}
        {!isPartsView && userPermissions.canViewPrices ? (
          <div id="quoteTotalsContainer">
            <div className="quote-totals-box">
              {showApproveEstimateModal ? (
                <ApproveEstimateModal
                  show={showApproveEstimateModal}
                  cancelHandler={() => setShowApproveEstimateModal(false)}
                  continueHandler={approveEstimate}
                />
              ) : null}
              {isCSR && showFinalizeROModal ? (
                <FinalizeROModal
                  show={showFinalizeROModal}
                  quoteSummary={quoteSummary}
                  closeHandler={closeFinalizeRoModal}
                  continueHandler={processFinalizeROModalPayload}
                  updatePaymentLines={updatePaymentLines}
                />
              ) : null}
              {isCSR &&
              showFinalizeROConfirmationModal &&
              !isSplitPaymentEnabled ? (
                <FinalizeROConfirmationModal
                  show={showFinalizeROConfirmationModal}
                  quoteSummary={quoteSummary}
                  updatedPayers={updatedPayers}
                  closeHandler={() => setShowFinalizeROConfirmationModal(false)}
                  printHandler={openDocumentsModal}
                />
              ) : null}
              {isCSR &&
              showFinalizeROConfirmationModal &&
              isSplitPaymentEnabled ? (
                <FinalizeROModal
                  showFinalizeModal={showFinalizeROConfirmationModal}
                  show={showFinalizeROConfirmationModal}
                  quoteSummary={quoteSummary}
                  updatedPayers={updatedPayers}
                  closeHandler={() => setShowFinalizeROConfirmationModal(false)}
                  printHandler={openDocumentsModal}
                />
              ) : null}
              {showDocumentsModal ? (
                <DocumentsModal
                  show={showDocumentsModal}
                  quoteSummary={quoteSummary}
                  closeHandler={() => setShowDocumentsModal(false)}
                  searchArchiveHandler={() => {
                    setShowDocumentsModal(false);
                    searchArchive();
                  }}
                  printSelectedDocumentsHandler={async docNames => {
                    setShowDocumentsModal(false);
                    await blockUntilCompleted(async () => {
                      await csrService.printSelectedDocuments(
                        quoteSummary.dealerCode ?? appContext.dealer.dealerCode,
                        isDmsPlusQuote
                          ? archivedQuoteData.quoteId
                          : quoteSummary.quoteId,
                        docNames,
                        quoteSummary
                      );
                    });
                  }}
                  dealerProperties={dealerProperties}
                />
              ) : null}
              {isCSR ? (
                <ErrorBoundary errorMessage="Unable to display totals">
                  <CheckoutSummaryComponent />
                </ErrorBoundary>
              ) : null}
              {!isCSR ? <QuoteSummary /> : null}
            </div>
          </div>
        ) : null}
      </div>
      {isCSR && !isEngageSource ? (
        <div className="sticky-footer">
          <div className="secondary-buttons flex-container">
            {docsButtonVisible ? (
              <Button
                size="lg"
                htmlId="documentsButton"
                buttonStyle={"secondary"}
                className={"right-margin"}
                onClick={openDocumentsModal}
                disabled={isDmsPlusQuote && !archivedQuoteData ? true : false}
              >
                {isNormalQuote ? (
                  "Documents"
                ) : isDmsPlusQuote && !loadingArchivedData ? (
                  "Documents"
                ) : (
                  <LoadingIndicator
                    htmlId="loadingIndicatorSmall"
                    floatToSide="left"
                  />
                )}
              </Button>
            ) : null}
            {appContext.isCreateSpecialPartsEnabled &&
            preRoServiceList.length > 0 ? (
              <PreRoDropdown
                totalServices={preRoServiceList.length}
                onCancelPreRo={cancelPreRo}
                onCreatePreRo={() => setShowPreROReasonModal(true)}
              />
            ) : null}
          </div>
          <div className="primary-buttons">
            {isPartsView || !canProceedWithRO ? (
              <Button
                htmlId="partsDoneButton"
                size="lg"
                onClick={closeRODetails}
              >
                Done
              </Button>
            ) : (
              <Button
                block
                size="lg"
                htmlId="proceedButton"
                disabled={csrProceedButtonState.isDisabled}
                title={csrProceedButtonState?.tooltipText || null}
                onClick={csrProceedButtonState.onClick}
              >
                <div
                  style={{
                    height: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <span style={{ textAlign: "center", flexGrow: 1 }}>
                    {csrProceedButtonState.displayText}
                  </span>

                  <span>
                    <IconKeyboardArrowRight htmlId="savequoteIcon" />
                  </span>
                </div>
              </Button>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default ServiceSummary;
/* eslint-enable no-console */
