// write all parts related util methods in this file. All other modules should use these methods.

import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import isNull from "lodash/isNull";
import cloneDeep from "lodash/cloneDeep";
import { priceSourceLabels } from "../constants/parts.constants";
import { getIsCorePart, generatePartId } from "./helper.util";
import { catalogSources } from "../../EditServiceModule/utils/edit-service.constants";

// common util to show/hide loading mask at parts grid cells
function resetDmsPending(parts, dmsPending) {
  parts.forEach(p => {
    if (p.oemPartNumber || p.partNumber) {
      p.dmsPending = dmsPending;
    }
  });
}
// Special case - Util used to read catalog part.priceSource and transform value into Standard values {MSRP, DMS}
const refinePriceSource = priceSource => {
  const formattedPriceSource = !isEmpty(priceSource)
    ? priceSource.toLowerCase(priceSource)
    : priceSourceLabels.MSRP.toLowerCase();
  const msrpRegEx = /msrp/i;
  const dmsRegEx = /dms/i;
  const manualRegEx = /manual/i;
  if (msrpRegEx.test(formattedPriceSource)) {
    return priceSourceLabels.MSRP;
  }
  if (dmsRegEx.test(formattedPriceSource)) {
    return priceSourceLabels.DMS;
  }
  if (manualRegEx.test(formattedPriceSource)) {
    return priceSourceLabels.MANUAL;
  }
  return priceSourceLabels.MSRP;
};

// convert API response of mediator call for parts
const convertPricingAndInventoryAPIDataUsingMediatorCall = (
  response,
  partsParam
) => {
  let dmsParts = [];
  const coreParts = [];
  if (!isEmpty(response.data.parts)) {
    let hasCorParts = false;
    let partsMap = {};
    const dmsPartsMap = response.data.parts
      .filter(p => !p.code)
      .reduce((map, obj) => {
        if (!obj.manufacturerPartNo && obj.manufacturerPartNoParent) {
          obj.partNumber = `${obj.manufacturerPartNoParent}CC`;
          hasCorParts = true;
        }
        map[obj.manufacturerPartNo || obj.partNumber] = obj;
        return map;
      }, {});
    console.log(
      "DMS PARTS API/response/payload",
      response.data.parts,
      partsParam
    );
    // if API has dmsParts then only return parts to edit service level, summary service/menu/modify parts cases
    if (!isEmpty(dmsPartsMap)) {
      response?.data?.parts?.forEach(overridePart => {
        overridePart?.isCorePart !== "true" &&
          dmsParts.push({
            partNumber: overridePart.manufacturerPartNo,
            manufacturerCode: overridePart.manufacturerCode
          });
      });

      if (hasCorParts) {
        partsMap = dmsParts.reduce((map, p) => {
          map[p.partNumber] = p;
          return map;
        }, {});
      }

      dmsParts.forEach(p => {
        if (p.partNumber) {
          // Note - UI derived field to show/hide load mask for DMS parts grid cells
          p.dmsPending = false;
          const dmsPart = dmsPartsMap[p.partNumber];
          if (dmsPart) {
            p.quantityAvailable = dmsPart?.quantityAvailable;
            // use partDescription to support paytypes change
            p.partDescription = dmsPart?.description || "";
            // Important: API field "partPrice" value saved in new derived field called "dmsPrice"
            p.dmsPrice = dmsPart?.unitPrice || null;
            p.unitPrice = dmsPart?.unitPrice || null;
            // Important: This new field "dmsSource" used to identify dmsPart or not in transform methods
            p.dmsSource = priceSourceLabels.DMS;
            p.costPrice = dmsPart.costPrice ? dmsPart.costPrice : null;
            p.isCorePart = getIsCorePart(dmsPart.isCorePart);
            p.bin = dmsPart.bin ? dmsPart.bin : null;
            p.type = dmsPart.type ? dmsPart.type : null;
            // p.location = dmsPart.location ? dmsPart.location : null;
            p.shelf = dmsPart.shelf ? dmsPart.shelf : null;
            p.unitCost = dmsPart.costPrice ? dmsPart.costPrice : null;
            p.message = dmsPart.message ? dmsPart.message : "";
            p.supersededPart = dmsPart?.supersededPart ?? null;
            // check core part
            if (hasCorParts) {
              const corePartNumber = `${p.partNumber}CC`;
              const corePart = dmsPartsMap[corePartNumber];
              if (corePart && !partsMap[corePartNumber]) {
                const dynamicPart = {
                  ...p,
                  ...corePart,
                  unitPrice: corePart.costPrice,
                  dmsPrice: corePart?.costPrice,
                  isCorePart: true
                };
                coreParts.push(dynamicPart);
              }
            }
          } else {
            // @note - For Non-DMS parts - set null to derived fields
            p.dmsPrice = null;
            p.dmsSource = null;
            p.dmsPending = false;
          }
        } // if ends partNumber
      });
    }
    dmsParts = dmsParts.concat(coreParts);
    resetDmsPending(dmsParts, false);
  } else {
    // when API returns empty dms parts
    resetDmsPending(dmsParts, false);
  }
  console.log("convertPricingAndInventoryAPIData", dmsParts);
  return dmsParts;
};

/**
 * util called in both EditService Module{edit service context}, ModifyPartsWrapper{modify parts context}
 * IMPORTANT - Util reads fields from converted API response (pricingAndInventory)
 * Method prepares Parts[] with UI derived fields used for selected parts grid binding
 * @param {*} dmsPartsList - DMS API response (converted)
 * @param {*} serviceParts - parts from service
 * @param {*} catalogSource - to detect service type used for MENU case
 * @param {*} hasDealerTire - TRUE - special check for Dealer Tire service, to update only quantityAvailable under Part
 * @param {*} isModifyFromSummary - False - as default value; True - if util method is called from modify parts link in summary page - service line
 * @return {*} Parts with DMS values
 */
const transformPartsWithDMSParts = (
  dmsPartsList,
  servicePartsInput,
  catalogSource,
  hasDealerTire = false,
  isModifyFromSummary = false
) => {
  let serviceParts = [...servicePartsInput];
  if (!isEmpty(dmsPartsList)) {
    const dmsPartsMap = dmsPartsList
      .filter(p => p.dmsSource)
      .reduce((map, obj) => {
        map[obj.partNumber] = obj;
        return map;
      }, {});
    serviceParts = attachCorePartsIfAny(serviceParts, dmsPartsList);

    serviceParts.forEach(part => {
      if (!isNull(part.oemPartNumber) && !(part?.partPrice < 0)) {
        const dmsPart = dmsPartsMap[part.oemPartNumber];
        if (dmsPart) {
          const dmsPriceVal = get(dmsPart, "dmsPrice", null);
          part.partPriceSource = getPartPriceSource(part, dmsPart);
          // Case1: All services - DMS API fields used to update {unitPrice, partName, quantityAvailable,dmsPrice}
          // Case2: Dealer Tire - Only {quantityAvailable} value used from DMS API; rest fields will be ignored
          if (!hasDealerTire) {
            part.dmsPrice = dmsPriceVal;
            // Update unitPrice with dmsPrice if it is dmsPart; else use from original part's unit price
            part.unitPrice = !dmsPriceVal ? part.unitPrice : dmsPriceVal;
            part.partName = isEmpty(part.partName)
              ? dmsPart.partDescription
              : part.partName;
          }
          part.quantityAvailable = dmsPart.quantityAvailable;
          part.costPrice = dmsPart.costPrice ? dmsPart.costPrice : null;
          part.unitCost =
            dmsPart?.unitCost && dmsPart?.unitCostOverride
              ? dmsPart.unitCost
              : dmsPart.costPrice ?? null;
          part.isCorePart = getIsCorePart(dmsPart.isCorePart);
          part.bin = dmsPart.bin ? dmsPart.bin : null;
          part.type = dmsPart.type ? dmsPart.type : null;
          part.location = dmsPart.location ? dmsPart.location : null;
          part.shelf = dmsPart.shelf ? dmsPart.shelf : null;
        } else {
          // For Non-DMS parts - set dmsPrice=null, partPriceSource as returned from API
          part.dmsPrice = null;
          part.partPriceSource = getPartPriceSource(part);
        }
        // priceSource is what saved to DB
        part.priceSource = part.partPriceSource;
        part.dmsPending = false;
        part.recordType = "RECOMMENDED";
        // isModifyFromSummary - passed as true- prevent auto-select parts coming from menu API
        if (catalogSource === catalogSources.MENU && !isModifyFromSummary) {
          part.selected = true;
        }
        part.originalPartInfo = part.originalPartInfo ?? null;
      }
    });
    resetDmsPending(serviceParts, false);
    console.log("transformPartsWithDMSParts", serviceParts);
  }
  return serviceParts;
};

// Replace this logic using partPriceSource
// NOTE: UI derived field "partPriceSource" to cover below cases to hold DMS api value and when user modifies unitPrice cell
// Case1: partPriceSource = null, default value; when API parts are read first time
// Case2: partPriceSource = MANUAL, when user modified unit price in grid; once Manual always Manual even if DMS price exist
// Case3: partPriceSource = DMS, when DMS API returns dmsPrice; mark is as "DMS" until if user modify dms price/msrp price from UI
// Case4: partPriceSource = MANUAL, when dealer tire service with parts; mark always as MANUAL even if DMS API returns dms price

function getPartPriceSource(part, dmsPart = null) {
  let partPriceSource = get(part, "partPriceSource", priceSourceLabels.MSRP);
  if (!isEmpty(dmsPart)) {
    const dmsPriceVal = get(dmsPart, "dmsPrice", null);
    if (partPriceSource !== priceSourceLabels.MANUAL) {
      partPriceSource = !isNull(dmsPriceVal)
        ? priceSourceLabels.DMS
        : priceSourceLabels.MSRP;
    }
  } else {
    partPriceSource =
      !isEmpty(partPriceSource) && partPriceSource !== priceSourceLabels.MANUAL
        ? priceSourceLabels.MSRP
        : priceSourceLabels.MANUAL;
  }
  return partPriceSource;
}
// util used for csr-part logic
function attachCorePartsIfAny(serviceParts, dmsPartsList) {
  const servicePartsMap = serviceParts
    .filter(p => p.oemPartNumber)
    .reduce((map, obj) => {
      map[obj.oemPartNumber] = obj;
      return map;
    }, {});
  dmsPartsList.forEach(dmsPart => {
    const part = servicePartsMap[dmsPart.partNumber];
    const parentPart = servicePartsMap[dmsPart.manufacturerPartNoParent];
    if (!part && parentPart) {
      const rowId = generatePartId();
      const extPartId = rowId;
      // check if we need to pass this sequence as partId
      const partId = rowId.toString();
      const corePart = {
        ...parentPart,
        ...dmsPart,
        oemPartNumber: dmsPart.partNumber,
        extPartId,
        rowId,
        partId
      };
      serviceParts.push(corePart);
    }
  });
  return serviceParts;
}
// Util called For Dealer Publish, Menu service cases: read object[rawOperationDetails] and transform parts from laborApps[0]
const transformParts = (parts, catalogSource = null) => {
  console.log("[parts util] transformParts called", catalogSource, parts);
  const rawParts = cloneDeep(parts);
  rawParts.forEach(part => {
    // IMPORTANT - Never override Set Default MSRP to priceSource for parts coming from catalog API response (menu, dealer pub, global repair)
    part.priceSource = refinePriceSource(part.priceSource);
    part.unitPrice = get(part, "unitPrice", 0);
    // @note: dealer Published and menu services should always be pre-selected
    if (catalogSource === catalogSources.MENU) {
      part.selected = get(part, "selected", true);
    }
    // derived fields
    part.partPriceSource = get(part, "partPriceSource", null);
    part.dmsPrice = null;
    part.dmsPending = false;
    part.recordType = "RECOMMENDED";
  });
  return rawParts;
};

const getPartDescription = (partName, oilType) => {
  if (!isEmpty(oilType)) {
    return `${partName} ${oilType}`;
  } else {
    return partName;
  }
};

/**
 *
 * @param {*} currentLookupParts in case of MENU this is service.parts [final parts added under the service]
 * @param {object} service this is a service carried from EditServiceModule as a prop
 */
const updateLookupPartsForMenus = (currentLookupParts, service) => {
  const partsIds = new Set(currentLookupParts.map(part => part.id));

  const missingParts = service.originalParts
    .filter(part => !partsIds.has(part.id))
    .map(part => ({
      ...part,
      selected: false
    }));

  currentLookupParts.push(...missingParts);
};

/**
 * Checks if the given object has either costPrice or unitCost attributes.
 * @param {Object} obj - The object to check for cost attributes.
 * @returns {boolean} - True if the object has costPrice or unitCost attributes, otherwise false.
 */
const hasCostAttributes = obj => {
  return obj.hasOwnProperty("costPrice") || obj.hasOwnProperty("unitCost");
};

// Helper function to check if a list of parts contains any part with cost attributes
const containsPartsWithCostAttributes = partsList =>
  partsList?.some(hasCostAttributes);

/**
 * Transforms an array of inventory parts locations into an array of objects with label and value properties.
 *
 * @param {Array<Object>} inventoryPartsLocation - Array of inventory parts location objects.
 * @param {string} inventoryPartsLocation[].name - The name of the inventory part location.
 * @returns {Array<Object>} An array of objects with label and value properties derived from the input array.
 */
const getInventoryPartsLocationItems = inventoryPartsLocation => {
  if (!Array.isArray(inventoryPartsLocation)) {
    return [];
  }

  return inventoryPartsLocation.map(location => ({
    label: location.name,
    value: location.name
  }));
};

function getUniqueDMSParts(parts) {
  const uniquePartsMap = {};

  parts.forEach(part => {
    const {
      manufacturerPartNo,
      quantityOnHand,
      quantityAvailable,
      unitPrice,
      costPrice,
      calculatedPrice,
      listPrice
    } = part;

    if (!uniquePartsMap[manufacturerPartNo]) {
      uniquePartsMap[manufacturerPartNo] = {
        ...part,
        quantityOnHand: 0,
        quantityAvailable: 0,
        unitPrice: 0,
        costPrice: 0,
        calculatedPrice: 0,
        listPrice: 0
      };
    }

    uniquePartsMap[manufacturerPartNo].quantityOnHand += quantityOnHand;
    uniquePartsMap[manufacturerPartNo].quantityAvailable += quantityAvailable;
    uniquePartsMap[manufacturerPartNo].unitPrice = Math.max(
      uniquePartsMap[manufacturerPartNo].unitPrice,
      unitPrice
    );
    uniquePartsMap[manufacturerPartNo].calculatedPrice = Math.max(
      uniquePartsMap[manufacturerPartNo].calculatedPrice,
      calculatedPrice
    );
    uniquePartsMap[manufacturerPartNo].listPrice = Math.max(
      uniquePartsMap[manufacturerPartNo].listPrice,
      listPrice
    );
    uniquePartsMap[manufacturerPartNo].costPrice = Math.max(
      uniquePartsMap[manufacturerPartNo].costPrice,
      costPrice
    );
  });

  return Object.values(uniquePartsMap);
}
export {
  resetDmsPending,
  refinePriceSource,
  convertPricingAndInventoryAPIDataUsingMediatorCall,
  transformPartsWithDMSParts,
  attachCorePartsIfAny,
  transformParts,
  getPartDescription,
  updateLookupPartsForMenus,
  hasCostAttributes,
  containsPartsWithCostAttributes,
  getInventoryPartsLocationItems,
  getUniqueDMSParts
};
