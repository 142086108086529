/**
 * Custom Hook for Labor fields
 */
import { useState, useEffect, useContext, useCallback } from "react";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import isNil from "lodash/isNil";
import isNumber from "lodash/isNumber";
import priceCalculationUtil from "../utils/service-calculation.util";
import * as gtmEvent from "../../features/utils/gtag/gtag-event.util";
import { formatLaborTime } from "../utils/time";
import { Actions, useEditServiceContext } from "../state/edit-service.context";
import { AppContext } from "../../state/app-context";
import { isDMSPlus, isServiceContract } from "../utils/service.util";
import {
  getWarrantyPlans,
  getLaborPriceService,
  getSpecialLaborPrice
} from "../service/labor-calculation.service";
import { getOperationDetailsParam } from "../utils/labor-calculation.util";
import {
  actionTypes,
  catalogSources,
  operationSources,
  payTypeCodes
} from "../utils/edit-service.constants";
import { toPriceString, toPriceStringNew } from "../utils/format.util";
import { FEES_TYPES } from "../../features/repair-order/constants/adjustment.constant";
import isArray from "lodash/isArray";
import {
  checkExpiredFeeOrDiscount,
  checkStartDateValidFeeOrDiscount
} from "../../features/page-wrapper/utils/quote-util";
import {
  formatVendorProduct,
  isServiceContractInsuranceSelected
} from "../../features/service-contract/utils/service-contracts.util";
import { isOtherPayerServiceContractSelected } from "../../features/service-contract/utils/service-contracts.util";
export default function useLaborCalculation(props) {
  const {
    defaultPayTypeCode,
    dealerCode,
    onChangePaytype,
    axiosInstance,
    defaultServiceTypeCode
  } = props;

  const { dispatch, state } = useEditServiceContext();
  const {
    payTypes,
    serviceTypes: serviceTypesList,
    vendorList,
    serviceContracts,
    quoteSummary,
    currentEditingService,
    originalService,
    service: stateService,
    vehicle,
    filteredParts,
    discountsAndFees,
    rawOperationDetails
  } = state;

  const {
    opCode: dmsOpcode,
    defaultLaborRate: laborRate,
    dealerLaborRateId
  } = stateService;

  const {
    labor,
    finalLaborPrice,
    payTypeCode,
    serviceTypeCode,
    operationSource
  } = stateService;

  const rawService = currentEditingService
    ? JSON.parse(currentEditingService?.quoteRawService?.rawService)
    : {};
  const isDeclinedService =
    currentEditingService?.operationSource === operationSources.DECLINED ||
    rawOperationDetails?.operationSource === operationSources.DECLINED;

  const appContext = useContext(AppContext);
  const {
    dealer: { dmsType },
    appType,
    dealerProperties
  } = appContext;
  const isDMSPlusDealer = isDMSPlus(dmsType);
  const isServiceContractPayType = isServiceContract(payTypeCode);
  const [payTypeConfirmationModal, setPayTypeConfirmationModal] =
    useState(false);

  const [
    selectedPayTypeBeforeConfirmation,
    setSelectedPayTypeBeforeConfirmation
  ] = useState(null);

  const BODY_SHOP = "BS"; // to render or not laborRate input
  const isServiceRO = appType === "CSR";
  const isAlwaysShowLaborRateEnabled =
    dealerProperties?.ALWAYS_SHOW_LABOR_RATE === "Y" &&
    (isServiceRO || isDMSPlusDealer);
  const isUseOEMWarrantyEnabled =
    dealerProperties?.ENGG_USE_OEM_WARRANTY === "Y";

  let laborTime = labor.time;
  let laborRateState = labor.laborRate;
  const { labor: originalLabor } = originalService;
  const {
    time: originalLaborTime,
    price: originalLaborPrice,
    laborRate: originalLaborRate
  } = originalLabor;

  // The utility formatLaborTime is used here to avoid issues caused by inconsistent type and format of originalLaborTime
  const [laborHours, setLaborHours] = useState(
    formatLaborTime(originalLaborTime, 0).toString()
  );
  const [laborRateOnFocus, setLaborRateOnFocus] = useState(null);
  const [laborPriceOnFocus, setLaborPriceOnFocus] = useState(null);
  // @note: read finalLaborPrice from state; And when user modify labor price field
  const [localLaborPrice, setLocalLaborPrice] = useState(
    toPriceString(finalLaborPrice)
  );
  // TODO: ADD: The Dropdown will always display a default service type.
  // TODO: EDIT: The Dropdown will always display the previously selected service type.
  const [serviceTypeTemp, setServiceTypeTemp] = useState(serviceTypeCode);
  const [localLaborRate, setLocalLaborRate] = useState(
    toPriceStringNew(laborRateState ?? null)
  );

  const [localSubTypeId, setLocalSubTypeId] = useState(
    currentEditingService?.subTypeId || stateService?.subTypeId
  );
  const [localCostAllocationSubTypeId, setLocalCostAllocationSubTypeId] =
    useState(
      currentEditingService?.allocationSubTypeId ||
        stateService?.allocationSubTypeId
    );
  const [newLocalCostAllocationName, setNewLocalCostAllocationName] = useState(
    currentEditingService?.internalAccount || stateService?.internalAccount
  );
  const [localOpCode, setLocalOpCode] = useState("");
  const [disablePaytype, setDisablePayType] = useState(false);
  const [disableServiceType, setDisableServiceType] = useState(false);
  const [defaultpayCodeID, setDefaultPayCodeID] = useState(
    defaultPayTypeCode || ""
  );
  const [defaultserviceCodeID, setDefaultServiceCodeID] = useState(
    !defaultServiceTypeCode ? "" : defaultServiceTypeCode
  );
  // @note: update this state either getLaborPrice API has price or fallback calculated price
  const [backupLaborPrice, setBackupLaborPrice] = useState("");

  // The selected value for this dropdown should be in the format "vendorName - productName". Concatenation is handled in the UI.
  const currentVendorName = formatVendorProduct(
    currentEditingService?.serviceContract?.vendorName,
    currentEditingService?.serviceContract?.productName
  );

  const [localVendorName, setLocalVendorName] = useState(currentVendorName);
  const [isInsuranceSelected, setIsInsuranceSelected] = useState(
    isServiceContractInsuranceSelected(currentEditingService?.serviceContract)
  );
  const [localServiceContractSelected, setLocalServiceContractSelected] =
    useState(currentEditingService?.serviceContract);
  const [localContractNumber, setLocalContractNumber] = useState(
    currentEditingService?.serviceContract?.contractNumber
  );

  const [localWarrantyPlanLabel, setLocalWarrantyPlanLabel] = useState(
    currentEditingService?.labor?.catalogLabor
      ? `${currentEditingService.labor.catalogLabor.warrantyType} - ${currentEditingService.labor.catalogLabor.planName}`
      : ""
  );

  // The "trackUseEffects=1" query param will enable extra logging and tracking for useEffects.
  const trackUseEffects = /[?&]trackUseEffects=1\b/.test(location.href);

  const log = useCallback((message, data) => {
    const timestamp = new Date().toISOString().replace(/[TZ]/g, " ").trim();
    console.log(
      `${timestamp} useLaborCalculation.js ${message}` +
        (data ? " " + JSON.stringify(data) : "")
    );
  }, []);

  const trackUseEffectStart = useCallback(
    (useEffectNumber, data) => {
      if (trackUseEffects) {
        window.sessionStorage.setItem(
          `useEffect #${useEffectNumber} last started`,
          new Date().getTime().toString()
        );
        log(`Starting useEffect #${useEffectNumber}`, data);
      }
    },
    [trackUseEffects, log]
  );

  const trackUseEffectEnd = useCallback(
    useEffectNumber => {
      if (trackUseEffects) {
        window.sessionStorage.setItem(
          `useEffect #${useEffectNumber} last ended`,
          new Date().getTime().toString()
        );
        log(`Completed useEffect #${useEffectNumber}`);
      }
    },
    [trackUseEffects, log]
  );

  // State for storing special labor prices locally
  const [localSpecialLaborPrice, setLocalSpecialLaborPrice] = useState([]);

  // Effect to update labor price calculation when special labor prices or selected service contract changes
  useEffect(() => {
    trackUseEffectStart(1, {
      localServiceContractSelected,
      localSpecialLaborPrice
    });
    // Ensure both special labor prices and a selected service contract are available
    if (localSpecialLaborPrice && localServiceContractSelected) {
      const specialLaborPrices = Array.isArray(localSpecialLaborPrice)
        ? localSpecialLaborPrice
        : [];

      // Helper function to find the matching special price
      const findSpecialPrice = () => {
        const { internalProductId, vendorId } = localServiceContractSelected;

        // Check by internalProductId first, fallback to vendorId if no match found
        return internalProductId
          ? specialLaborPrices?.find(
              price => price?.serviceContractPolicyId === internalProductId
            ) ||
              specialLaborPrices?.find(
                price => price?.serviceContractSupplierId === vendorId
              )
          : specialLaborPrices?.find(
              price => price?.serviceContractSupplierId === vendorId
            );
      };

      // Find the special price and set the labor rate, defaulting to 0 if not found
      const specialPrice = findSpecialPrice();
      const rate = specialPrice?.laborRate || 0;

      // Update labor price calculation with the found hourly rate
      updateLaborPriceCalculation({
        hourlyRate: Number(rate),
        isAPILaborPrice: true
      });
    }

    trackUseEffectEnd(1);
  }, [
    localServiceContractSelected,
    localSpecialLaborPrice,
    trackUseEffectStart,
    trackUseEffectEnd
  ]);

  useEffect(() => {
    trackUseEffectStart(2, { defaultPayTypeCode });
    setDefaultPayCodeID(!defaultPayTypeCode ? "" : defaultPayTypeCode);
    trackUseEffectEnd(2);
  }, [defaultPayTypeCode, trackUseEffectStart, trackUseEffectEnd]);

  useEffect(() => {
    trackUseEffectStart(3, { defaultServiceTypeCode });
    setDefaultServiceCodeID(
      !defaultServiceTypeCode ? "" : defaultServiceTypeCode
    );
    trackUseEffectEnd(3);
  }, [defaultServiceTypeCode, trackUseEffectStart, trackUseEffectEnd]);

  useEffect(() => {
    trackUseEffectStart(4, { operationSource: stateService.operationSource });
    if (
      stateService.operationSource === catalogSources.MENU ||
      quoteSummary?.payers?.some(p => !!p.closedDate)
    ) {
      setDisablePayType(true);
    }
    trackUseEffectEnd(4);
  }, [
    stateService.operationSource,
    quoteSummary,
    trackUseEffectStart,
    trackUseEffectEnd
  ]);

  useEffect(() => {
    trackUseEffectStart(5, { operationSource: stateService.operationSource });
    if (
      stateService.operationSource === catalogSources.MENU ||
      quoteSummary?.payers?.some(p => !!p.closedDate)
    ) {
      setDisableServiceType(true);
    }
    trackUseEffectEnd(5);
  }, [
    stateService.operationSource,
    quoteSummary,
    trackUseEffectStart,
    trackUseEffectEnd
  ]);

  useEffect(() => {
    trackUseEffectStart(6, {
      payTypeCode,
      isUseOEMWarrantyEnabled,
      dealerCode,
      make: vehicle?.make
    });
    if (payTypeCode === payTypeCodes.WARRANTY && isUseOEMWarrantyEnabled) {
      getWarrantyPlans(
        { dealerCode, make: vehicle?.make },
        axiosInstance.getInstance()
      )
        .then(result => {
          dispatch({
            type: Actions.SET_WARRANTY_PLANS,
            payload: result
          });
        })
        .catch(err => {
          console.log(err);
          dispatch({
            type: Actions.SET_WARRANTY_PLANS,
            payload: []
          });
        });
    }
    trackUseEffectEnd(6);
  }, [
    payTypeCode,
    isUseOEMWarrantyEnabled,
    dealerCode,
    vehicle?.make,
    axiosInstance,
    dispatch,
    trackUseEffectStart,
    trackUseEffectEnd
  ]);

  // @note - we no longer need to restrict call to getLabor price even if the prices are comming as overriden
  useEffect(() => {
    trackUseEffectStart(7, { payTypeCode, serviceTypeCode, laborTime });
    if (["C", "I", "S"].includes(payTypeCode) || !isUseOEMWarrantyEnabled) {
      if (payTypeCode || serviceTypeCode) {
        getLaborPrice();
      } else {
        updateLaborPriceCalculation();
      }
    }
    trackUseEffectEnd(7);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    payTypeCode,
    serviceTypeCode,
    laborTime,
    trackUseEffectStart,
    trackUseEffectEnd
  ]);

  // @csr-logic

  // @note: updating laborRate by dividing laborPrice and laborHours only if laborRateState not available
  useEffect(() => {
    trackUseEffectStart(8, { finalLaborPrice });

    // If the total labor price has been overridden, don't recalculate the labor rate.
    if (!isNil(stateService.totalLaborPriceOverride)) {
      trackUseEffectEnd(8);
      return;
    }

    if (!isNil(finalLaborPrice)) {
      const isMissingLaborRateState = laborRateState == null;
      const isCustomerOrInternalPay = ["C", "I"].includes(payTypeCode);
      const isLaborRateOverridden = !isNil(stateService.laborRateOverride);
      const isTotalLaborPriceOverridden = !isNil(
        stateService.totalLaborPriceOverride
      );

      if (
        payTypeCode === "S" ||
        isMissingLaborRateState ||
        (isCustomerOrInternalPay &&
          !isLaborRateOverridden &&
          !isTotalLaborPriceOverridden) ||
        (payTypeCode === "W" &&
          !isUseOEMWarrantyEnabled &&
          !isLaborRateOverridden &&
          !isTotalLaborPriceOverridden)
      ) {
        const laborRateCalculated =
          laborHours != 0 ? finalLaborPrice / laborHours : 0;
        setLocalLaborRate(toPriceString(laborRateCalculated));

        const changed =
          parseFloat(laborRateCalculated.toFixed(2)) !== originalLaborRate;
        dispatch({
          type: Actions.SET_CHANGED,
          payload: {
            field: "laborRate",
            value: changed
          }
        });
        dispatch({
          type: Actions.SET_LABOR_RATE,
          payload: {
            laborRate: parseFloat(laborRateCalculated.toFixed(2))
          }
        });
      }
    }
    trackUseEffectEnd(8);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalLaborPrice, trackUseEffectStart, trackUseEffectEnd]);

  const getLaborPrice = async () => {
    // When the labor rate has been overridden, keep the calculated labor price instead of retrieving it from catalog.
    if (!isNil(stateService?.labor?.laborRateOverride)) {
      return;
    }

    // When the OEM Warranty flag is on, and it's a warranty service with a warranty plan selected,
    // we want to keep that plan's labor rate and the calculated total labor price.
    if (
      isUseOEMWarrantyEnabled &&
      stateService?.payTypeCode === payTypeCodes.WARRANTY &&
      !isNil(stateService?.labor?.catalogLabor)
    ) {
      return;
    }

    // fix - allow API call when global repair/non-global service has empty laborApps
    const isMenuPackage = operationSources.MENU === operationSource ? 1 : 0;
    // @note: Usage of spread operator below.
    // If dealerLaborRateId arrives as null, it won't be included in the payload; an empty object gets spread.
    // If it arrives with a value, it does get sent; { dealerLaborRateId } gets spread into the payload object.
    // TODO: WA - sending fallbackServiceTypeCode = MR for MENU services because serviceTypeCode is coming as undefined
    //  get the serviceTypeCode correctly when MENU is implemented
    // const fallbackServiceTypeCode = !!isMenuPackage ? "MR" : null;

    const isDealerPublishedOrGlobal =
      stateService.operationSource === catalogSources.DEALERCATALOG ||
      stateService.operationSource === catalogSources.GLOBALCATALOG ||
      (isDeclinedService &&
        (rawService?.catalogSource === catalogSources.DEALERCATALOG ||
          rawOperationDetails?.catalogSource ===
            catalogSources.DEALERCATALOG)) ||
      (isDeclinedService &&
        (rawService?.catalogSource === catalogSources.GLOBALCATALOG ||
          rawOperationDetails?.catalogSource === catalogSources.GLOBALCATALOG));

    const isServiceTypeFeatureEnabled =
      isDMSPlusDealer &&
      serviceTypesList?.length > 0 &&
      isDealerPublishedOrGlobal;

    const getDispatchSkillLevel = () =>
      getOperationDetailsParam(
        "dispatchSkillLevel",
        stateService,
        "skillLevel",
        currentEditingService
      );

    const skillLevelDeclined =
      stateService?.labor?.laborCostMethod ??
      currentEditingService?.labor?.laborCostMethod ??
      getDispatchSkillLevel();

    const skillLevel = isDeclinedService
      ? skillLevelDeclined
      : getDispatchSkillLevel();

    const make = isDealerPublishedOrGlobal
      ? vehicle?.make ??
        getOperationDetailsParam(
          "make",
          stateService ?? {},
          "make",
          currentEditingService ?? {}
        )
      : vehicle?.make ?? "";

    const getLaborPayload = () => {
      if (payTypeCode === payTypeCodes.SERVICE_CONTRACT && isDMSPlusDealer) {
        return {
          dealerCode,
          vehicle: {
            ...vehicle,
            make
          },
          payTypeCode
        };
      }

      return {
        dealerCode,
        laborHours: laborTime,
        vehicle: {
          ...vehicle,
          make
        },
        payTypeCode,
        // Note: set default serviceTypeCode instead of sending null to the API
        serviceTypeCode: serviceTypeCode || "MR",
        ...(isServiceTypeFeatureEnabled && { skillLevel }),
        isMenuPackage,
        ...(!isNull(dealerLaborRateId) ? { dealerLaborRateId } : {})
      };
    };

    try {
      const responseLaborPrice = await (payTypeCode ===
        payTypeCodes.SERVICE_CONTRACT && isDMSPlusDealer
        ? getSpecialLaborPrice(getLaborPayload(), axiosInstance.getInstance())
        : getLaborPriceService(getLaborPayload(), axiosInstance.getInstance()));

      // Check if the payment type is a service contract and update local special labor price if true
      if (payTypeCode === payTypeCodes.SERVICE_CONTRACT) {
        setLocalSpecialLaborPrice(responseLaborPrice);
        return;
      }

      const laborPrice = responseLaborPrice?.laborPrice;
      const hourlyRate = responseLaborPrice?.hourlyRate;

      if (!isNil(laborPrice) || !isNil(hourlyRate)) {
        updateLaborPriceCalculation({
          laborPrice,
          hourlyRate,
          isAPILaborPrice: true
        });
      } else {
        updateLaborPriceCalculation({ isAPILaborPrice: true });
      }
    } catch (error) {
      updateLaborPriceCalculation({ isAPILaborPrice: true });
    }
  };

  // @todo-edit: fallback logic trigger when API failed or first place when edit page rendered
  const updateLaborPriceCalculation = ({
    laborPrice = null,
    hourlyRate = null,
    laborRateNumber,
    type,
    isAPILaborPrice = false,
    laborRateOverride = null
  } = {}) => {
    const fallbackPrice = (laborRateState || laborRate) * laborTime;
    const serviceContractPrice = isNumber(hourlyRate)
      ? Number(hourlyRate) * laborTime
      : null;
    const price = isNumber(laborPrice) ? Number(laborPrice) : null;
    const calculatedPrice = isNumber(serviceContractPrice)
      ? serviceContractPrice
      : isNumber(price)
      ? price
      : fallbackPrice;
    // @todo-task: replace this dispatch by call util to re-calculate prices later
    const updatedService = priceCalculationUtil.recalculatePrices(
      stateService,
      type || actionTypes.LABOR,
      calculatedPrice
    );

    if (isNumber(hourlyRate)) {
      updatedService.laborRate = hourlyRate;
    }
    if (laborRateNumber) {
      updatedService.labor = {
        ...updatedService.labor,
        laborRate: laborRateNumber
      };
    }
    if (isNumber(laborRateOverride)) {
      updatedService.labor = {
        ...updatedService.labor,
        laborRateOverride
      };
    }

    if (isAPILaborPrice) {
      setBackupLaborPrice(toPriceString(calculatedPrice));
    }
    dispatch({
      type: Actions.SET_SERVICE,
      payload: updatedService
    });
  };

  const showConfirmationModalForPayType = (cxEvent, action) => {
    setSelectedPayTypeBeforeConfirmation(cxEvent);
    setPayTypeConfirmationModal(action);
  };

  const confirmPayTypeChange = () => {
    handlePayTypeChange(selectedPayTypeBeforeConfirmation);
    showConfirmationModalForPayType(null, false);
  };

  const handlePayTypeChange = e => {
    setDefaultPayCodeID(e.target.value);
    if (e.target.value !== "I") {
      setPayTypeSubType(null);
      setPayTypeCostAllocation(null);
    }
    const editedService = cloneDeep(stateService);
    const selection = payTypes.find(
      pt => pt.dealerPayTypeId.toString() === e.target.value
    );
    const changed = selection.payCode !== defaultpayCodeID;
    gtmEvent.trackGAEventWithParam("ga.newquote.edit_service_pay_type_click", {
      result: `${stateService.operationSource} - ${selection.description}`,
      location: "edit-service"
    });
    const {
      payCode: selectedPayCode,
      description: selectedDescription,
      payTypeGroup: selectedPayTypeGroup
    } = selection;

    const serviceDiff = {
      payTypeCode: selectedPayCode,
      payTypeDescription: selectedDescription,
      payTypeGroup: selectedPayTypeGroup,
      labor: {}
    };

    const rawService = JSON.parse(
      currentEditingService?.quoteRawService?.rawService ?? "null"
    );
    const laborApp = rawService?.laborApps?.length
      ? rawService.laborApps[0]
      : null;

    if (!isNil(rawService?.defaultLaborRate)) {
      serviceDiff.defaultLaborRate = rawService.defaultLaborRate;
    }

    if (!isNil(laborApp?.laborHours)) {
      serviceDiff.labor.time = laborApp.laborHours;
      serviceDiff.labor.laborTime = laborApp.laborHours * 60;
      setLaborHours(laborApp.laborHours.toString());
    }

    if (
      ["C", "I"].includes(selectedPayCode) ||
      (selectedPayCode === "W" && !isUseOEMWarrantyEnabled)
    ) {
      // Reset the labor pricing from the selected operation.
      if (!isNil(laborApp?.laborPrice)) {
        serviceDiff.finalLaborPrice = laborApp.laborPrice;
        serviceDiff.labor.laborPrice = laborApp.laborPrice;
        serviceDiff.labor.price = laborApp.laborPrice;
        setLocalLaborPrice(laborApp.laborPrice.toFixed(2));
      }
      if (
        !isNil(laborApp?.laborPrice) &&
        !isNil(laborApp?.laborHours) &&
        laborApp?.laborHours > 0
      ) {
        const calculatedLaborRate =
          Math.round((laborApp.laborPrice / laborApp.laborHours) * 100) / 100;
        serviceDiff.laborRate = calculatedLaborRate;
        serviceDiff.labor.laborRate = calculatedLaborRate;
        setLocalLaborRate(calculatedLaborRate.toFixed(2));
      }
    } else if (selectedPayCode === "W") {
      const rate = stateService?.labor?.catalogLabor?.laborRate ?? 0;
      const laborPrice = laborTime * rate;
      serviceDiff.calculatedLaborPrice = laborPrice;
      serviceDiff.laborPrice = laborPrice;
      serviceDiff.finalLaborPrice = laborPrice;
      serviceDiff.labor.laborPrice = laborPrice;
      serviceDiff.labor.price = laborPrice;
      setLocalLaborPrice(toPriceString(laborPrice));
      serviceDiff.laborRate = rate;
      serviceDiff.labor.laborRate = rate;
      setLocalLaborRate(toPriceString(rate));
      laborRateState = rate;
    } else {
      // For service contracts & OEM warranties, set the labor pricing to zero.
      // They will get updated when the user makes a selection from the special dropdown for this pay type.
      serviceDiff.calculatedLaborPrice = 0;
      serviceDiff.laborPrice = 0;
      serviceDiff.finalLaborPrice = 0;
      serviceDiff.labor.laborPrice = 0;
      serviceDiff.labor.price = 0;
      setLocalLaborPrice("0.00");
      serviceDiff.laborRate = 0;
      serviceDiff.labor.laborRate = 0;
      setLocalLaborRate("0.00");
      laborRateState = 0;
    }

    // @note: Update all paytype values with one action
    dispatch({
      type: Actions.MODIFY_SERVICE,
      payload: {
        diff: serviceDiff,
        eraseLaborRateOverride: true,
        eraseTotalLaborPriceOverride: true
      }
    });

    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "payType",
        value: changed
      }
    });

    if (stateService.catalogSource === "GlobalCatalog") {
      editedService.parts = filteredParts;
    }
    const updatedCatalogFees =
      getUpdatedCatalogFeesAfterPayTypeChange(selectedPayCode);
    const updatedCatalogDiscounts =
      getUpdatedDiscountAfterPayTypeChange(selectedPayCode);
    dispatch({
      type: Actions.SET_CATALOG_FEES,
      payload: updatedCatalogFees
    });
    dispatch({
      type: Actions.SET_CATALOG_DISCOUNTS,
      payload: updatedCatalogDiscounts
    });
    dispatch({
      type: Actions.SET_FILTERED_PARTS,
      payload: editedService.parts
    });
    onChangePaytype(selection.payCode, editedService);
  };

  const getUpdatedCatalogFeesAfterPayTypeChange = selectedPayCode => {
    console.log("selectedPayCode", selectedPayCode, stateService);
    let catalogFees = cloneDeep(stateService?.catalogFees);
    //* For filtering fees that are not applicable to changed payType
    catalogFees = catalogFees?.filter(
      fee =>
        fee?.payTypes?.findIndex(f => f === selectedPayCode) !== -1 &&
        fee.applyFeeSvcLine === 1
    );
    //* for adding mandatory fee that is applicable to changed payType
    discountsAndFees?.fees?.map(fees => {
      const feesPayTypesSupported = !isArray(fees?.payTypes)
        ? fees?.payTypes?.split(",")
        : fees?.payTypes;
      const isFeeAlreadyAdded = catalogFees.findIndex(
        serviceFee => serviceFee?.dealerFeesId + "" === fees?.dealerFeesId + ""
      );
      if (
        fees?.applyFeeSvcLine === 1 &&
        feesPayTypesSupported.findIndex(f => f === selectedPayCode) !== -1 &&
        isFeeAlreadyAdded === -1 &&
        fees?.applyToOverallTotal !== 1 &&
        fees?.feesType !== "Variable" &&
        checkExpiredFeeOrDiscount(fees) &&
        checkStartDateValidFeeOrDiscount(fees)
      ) {
        fees.feeMax =
          fees?.feesType === FEES_TYPES.DOLLAR ? null : fees?.feeMax;
        fees.payTypes = !isArray(fees?.payTypes)
          ? fees?.payTypes?.split(",")
          : fees?.payTypes;
        catalogFees?.push(fees);
      }
    });
    return catalogFees;
  };

  const getUpdatedDiscountAfterPayTypeChange = selectedPayCode => {
    let catalogDiscounts = cloneDeep(stateService?.catalogDiscounts);

    //* For filtering discounts that are not applicable to changed payType
    catalogDiscounts = catalogDiscounts?.filter(
      disc => disc?.payTypes?.findIndex(f => f === selectedPayCode) !== -1
    );
    console.debug(catalogDiscounts);
    //* returning blank, as we need to drop all the disc on payType change
    return [];
  };

  const handleServiceTypeChange = e => {
    const { value } = e.target;
    setDefaultServiceCodeID(value);
    const editedService = cloneDeep(stateService);
    const selection = serviceTypesList.find(
      st => st.dealerServiceTypeId.toString() === value
    );
    setServiceTypeTemp(selection?.serviceTypeCode); // BS, MR, QL
    const changed = selection.serviceTypeCode !== defaultpayCodeID;
    const {
      serviceTypeCode: selectedServiceTypeCode,
      description: selectedServiceTypeDescription
    } = selection;
    // update all serviceTypes values with one action
    dispatch({
      type: Actions.SET_SERVICETYPE_DETAILS,
      payload: {
        serviceTypeCode: selectedServiceTypeCode, // MR, BS, QL
        serviceTypeDescription: selectedServiceTypeDescription // "Mechanical Repair"
      }
    });
    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "serviceType",
        value: changed
      }
    });
    if (stateService.catalogSource === "GlobalCatalog") {
      editedService.parts = filteredParts;
    }
    // @note: update selected service type data to editedService
    editedService.serviceTypeCode = selectedServiceTypeCode;
    editedService.serviceTypeDescription = selectedServiceTypeDescription;
    props.onChangeServiceType(selection.serviceTypeCode, editedService);
  };

  const handleServiceContractChange = e => {
    dispatch({
      type: Actions.MODIFY_SERVICE,
      payload: {
        eraseLaborRateOverride: true,
        eraseTotalLaborPriceOverride: true
      }
    });

    const selectedServiceContract = e.target.value?.[0];
    const editedService = cloneDeep(stateService);
    const selection = vendorList.find(
      sc => sc.vendor.toString() === selectedServiceContract?.value
    );
    const isInsurance = isServiceContractInsuranceSelected(selection);
    const isOtherPayerServiceContract =
      isOtherPayerServiceContractSelected(selection);

    let serviceContractDetails = null;
    if (isInsurance || isOtherPayerServiceContract) {
      serviceContractDetails = vendorList?.find(
        sc => sc.vendorId === selection?.vendorId
      );
    } else {
      serviceContractDetails = serviceContracts?.find(
        sc => sc.internalProductId === selection?.internalProductId
      );
    }

    const changed = selection !== localVendorName;
    setLocalVendorName(selection?.vendor);
    setIsInsuranceSelected(isInsurance);
    setLocalServiceContractSelected(serviceContractDetails);
    setLocalContractNumber(null);
    console.log("serviceContractDetails", serviceContractDetails, selection);
    dispatch({
      type: Actions.SET_SERVICE_CONTRACT_DETAILS,
      payload: serviceContractDetails
    });
    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "serviceContract",
        value: changed
      }
    });

    if (isDMSPlus(appContext.dealer.dmsType)) {
      // @note: update selected service contract details to editedService
      editedService.serviceContract = serviceContractDetails;
      props.onChangeServiceContract(serviceContractDetails, editedService);
    }
  };

  const handleWarrantyPlanChange = event => {
    const selectedValue = event.target.value;
    const plan = state.warrantyPlans.find(
      plan => `${plan.warrantyType} - ${plan.planName}` === selectedValue
    );
    setLocalWarrantyPlanLabel(selectedValue);

    const laborRate = plan.laborRate;
    if (laborRate !== null && laborRate !== undefined) {
      setLocalLaborRate(toPriceString(laborRate));
      updateLaborPriceCalculation({
        hourlyRate: laborRate
      });
    }

    dispatch({
      type: Actions.SET_SELECTED_WARRANTY_PLAN,
      payload: plan
    });
  };

  const handleContractNumberChange = event => {
    const { value: contractNumber } = event.target;
    if (contractNumber) {
      setLocalContractNumber(contractNumber);
      dispatch({
        type: Actions.SET_SERVICE_CONTRACT_DETAILS,
        payload: { ...localServiceContractSelected, contractNumber }
      });
    } else {
      setLocalContractNumber(null);
    }
  };

  const setPayTypeSubType = subTypeId => {
    setLocalSubTypeId(subTypeId);
    dispatch({
      type: Actions.SET_PAY_TYPE_SUB_TYPE,
      payload: subTypeId
    });
    const changed = subTypeId !== originalService.subTypeId;
    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "subTypeId",
        value: changed
      }
    });
  };

  const setPayTypeCostAllocation = allocationSubTypeId => {
    console.log(allocationSubTypeId);
    setLocalCostAllocationSubTypeId(allocationSubTypeId?.value);
    dispatch({
      type: Actions.SET_PAY_TYPE_COST_ALLOCATION,
      payload: allocationSubTypeId?.value
    });
    const changed =
      allocationSubTypeId?.value !== originalService.allocationSubTypeId;
    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "allocationSubTypeId",
        value: changed
      }
    });
  };

  const handlePayTypeSubTypeChange = cxEvent => {
    setPayTypeSubType(cxEvent.target.value);
  };

  const handlePayTypeCostAllocationChange = cxEvent => {
    setPayTypeCostAllocation(cxEvent.target.value[0]);
  };

  // new cost allocation
  const setNewPayTypeCostAllocation = costAllocObject => {
    console.log(costAllocObject);
    setNewLocalCostAllocationName(costAllocObject?.value);
    dispatch({
      type: Actions.SET_PAY_TYPE_NEW_COST_ALLOCATION_TYPE,
      payload: costAllocObject?.value
    });
    const changed = costAllocObject?.value !== originalService.internalAccount;
    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "internalAccount",
        value: changed
      }
    });
  };

  const handleNewPayTypeCostAllocationChange = cxEvent => {
    setNewPayTypeCostAllocation(cxEvent.target.value[0]);
  };

  // @note: Global Repair - FIX side-effect where labor.time and labor.price are null and
  // And updated after clicking "DONE", and therefore change from  0 default to a value.
  // @DEBUG with GLOBAL OPS
  useEffect(() => {
    trackUseEffectStart(9, { labor, originalLaborTime, originalLaborPrice });
    if (!isNull(labor.time) && labor.time !== originalLaborTime) {
      const time = formatLaborTime(labor.time, 0);
      setLaborHours(time.toString());
      console.log("GLOBAL LABOR TIME", time);
    }
    trackUseEffectEnd(9);
  }, [
    labor,
    originalLaborTime,
    originalLaborPrice,
    trackUseEffectStart,
    trackUseEffectEnd
  ]);

  // @note: This useEffect required, to update localLaborPrice {binded to UI} whenever finalLaborPrice updated in context
  useEffect(() => {
    trackUseEffectStart(10, { finalLaborPrice });
    const strFinalLaborPrice = toPriceString(finalLaborPrice);
    setLocalLaborPrice(strFinalLaborPrice);
    trackUseEffectEnd(10);
  }, [finalLaborPrice, trackUseEffectStart, trackUseEffectEnd]);

  // monitor for opCode changes from context.service
  useEffect(() => {
    trackUseEffectStart(11, { opCode: stateService.opCode });
    if (stateService.opCode) {
      const opCodes = !isEmpty(stateService.opCode)
        ? stateService.opCode.split(";")
        : [];
      const opCodeSelected = !isEmpty(opCodes)
        ? opCodes[0]
        : stateService.opCode;
      setLocalOpCode(opCodeSelected);
    }
    trackUseEffectEnd(11);
  }, [stateService.opCode, trackUseEffectStart, trackUseEffectEnd]);

  const handleLaborPriceChange = event => {
    const { value } = event.target;
    if (!isEmpty(value)) {
      const priceValue = Number(value);
      setLocalLaborPrice(toPriceString(priceValue));
    } else {
      setLocalLaborPrice("");
    }
  };

  const handleLaborRateChange = event => {
    const { value } = event.target;
    setLocalLaborRate(toPriceString(value));
  };

  const onBlurLaborRate = () => {
    if (!isEmpty(localLaborRate)) {
      const laborRateNumber = parseFloat(localLaborRate);
      const error = laborRateNumber > 999.99;
      const changed = laborRateNumber !== parseFloat(laborRateOnFocus);
      let laborRateOverride;
      if (changed) {
        dispatch({
          type: Actions.MODIFY_SERVICE,
          payload: {
            diff: {
              labor: {
                laborRate: laborRateNumber,
                laborRateOverride: laborRateNumber
              }
            }
          }
        });
        dispatch({
          type: Actions.SET_CHANGED,
          payload: {
            field: "laborRate",
            value: changed
          }
        });
        dispatch({
          type: Actions.SET_CHANGED,
          payload: {
            field: "laborRateOverride",
            value: changed
          }
        });
      }
      dispatch({
        type: Actions.SET_ERRORS,
        payload: {
          field: "laborRate",
          value: error
        }
      });
      if (changed && !error) {
        laborRateOverride = laborRateNumber;
        dispatch({
          type: Actions.SET_CHANGED,
          payload: {
            field: "laborRateOverride",
            value: changed
          }
        });
      }
      if (!stateService?.laborPriceOverridden) {
        // For ServiceTypes as BodyShop the only way to update/override total price is thru labor rate
        // cause Total is disabled for this serviceTypes
        const totalLabor = parseFloat(Number(laborHours) * localLaborRate);
        processPriceChanges(
          totalLabor,
          laborRateNumber,
          false,
          laborRateOverride
        );
      }
    }
  };

  const handleLaborTimeChange = event => {
    const { value } = event.target;
    let newLaborHours = "";
    if (value) {
      if (value.includes("_")) {
        newLaborHours = "0.";
      } else {
        newLaborHours = value;
      }
    } else {
      newLaborHours = "";
    }
    setLaborHours(newLaborHours);
    if (!isEmpty(newLaborHours)) {
      const newLaborHourNumber = parseFloat(newLaborHours);
      const changed = newLaborHourNumber !== originalLaborTime;
      console.log("DEBUG labor time change event", changed);
    }
  };

  const handleOpCodeChange = event => {
    const { value: code } = event.target;
    if (code) {
      setLocalOpCode(code);
    } else {
      setLocalOpCode("");
    }
  };

  const onBlurLaborTime = () => {
    if (!isEmpty(laborHours)) {
      const laborHourNumber = parseFloat(laborHours);
      const changed = laborHourNumber !== originalLaborTime;
      const error = laborHourNumber > 999.99;
      laborTime = laborHourNumber;

      if (payTypeCode === payTypeCodes.WARRANTY && isUseOEMWarrantyEnabled) {
        const laborRate = parseFloat(localLaborRate);
        const totalLabor = laborHourNumber * laborRate;
        processPriceChanges(totalLabor, laborRate, false);
      } else {
        if (stateService?.totalLaborPriceOverride) {
          // Per logic for US1491994, if the total labor price was previously overridden, don't recalculate it,
          // and also don't re-calculate the labor rate if the hours change.
        } else {
          const totalLabor = parseFloat(Number(laborHours) * localLaborRate);
          processPriceChanges(totalLabor, parseFloat(localLaborRate), false);
          dispatch({
            type: Actions.SET_LABOR_RATE,
            payload: {
              laborRate: parseFloat(localLaborRate)
            }
          });
          const changed = parseFloat(localLaborRate) !== originalLaborRate;
          dispatch({
            type: Actions.SET_CHANGED,
            payload: {
              field: "laborRate",
              value: changed
            }
          });
        }
      }

      dispatch({
        type: Actions.SET_CHANGED,
        payload: {
          field: "laborTime",
          value: changed
        }
      });

      dispatch({
        type: Actions.SET_LABOR_TIME,
        payload: Number(laborHours)
      });

      dispatch({
        type: Actions.SET_ERRORS,
        payload: {
          field: "laborTime",
          value: error
        }
      });
    }
  };

  const onFocusLaborRate = () => {
    setLaborRateOnFocus(localLaborRate);
  };

  const onFocusLaborPrice = () => {
    setLaborPriceOnFocus(localLaborPrice);
  };

  const onBlurLaborPrice = () => {
    let dirty = false;
    const changed =
      parseFloat(localLaborPrice) !== parseFloat(laborPriceOnFocus);
    if (!isEmpty(String(localLaborPrice))) {
      // eslint-disable-next-line unused-imports/no-unused-vars
      dirty = true;
      const laborPriceNumber = parseFloat(localLaborPrice);
      processPriceChanges(laborPriceNumber, undefined, changed);

      if (laborHours) {
        // when overriding laborPrice value, change laborrate in state
        dispatch({
          type: Actions.SET_CHANGED,
          payload: {
            field: "laborRate",
            value: changed
          }
        });
        dispatch({
          type: Actions.SET_LABOR_RATE,
          payload: {
            laborRate: parseFloat(localLaborRate)
          }
        });
      }

      if (changed) {
        updateLaborPriceCalculation({
          laborPrice: laborPriceNumber,
          type: actionTypes.LABOR_OVERRIDE
        });
      }
    } else {
      // @note: When Labor Price modified as blank, retain field with {API retuned price value}
      setLocalLaborPrice(backupLaborPrice);
      dispatch({
        type: Actions.SET_CHANGED,
        payload: {
          field: "laborPrice",
          value: changed
        }
      });
      updateLaborPriceCalculation({
        laborPrice: backupLaborPrice,
        type: actionTypes.LABOR_OVERRIDE
      });
    }
  };
  /**
   * Purpose: Util called to update context sevice fields  with recalculation logic
   * @param {number} priceParam - labor price field value convert in to float before comparing with original prices
   * @param {number} laborRateNumber - labor rate
   * @param {boolean} isNewManualTotalLaborPriceOverride - flag indicating whether this call is the result of
   *                  the total labor price having just been manually overridden
   */
  const processPriceChanges = (
    priceParam = 0,
    laborRateNumber,
    isNewManualTotalLaborPriceOverride,
    laborRateOverride
  ) => {
    const price = parseFloat(priceParam.toFixed(2));
    const changed = price !== parseFloat(finalLaborPrice.toFixed(2));
    const error = price > 99999.99;
    if (changed) {
      updateLaborPriceCalculation({
        laborPrice: price,
        type: !isNewManualTotalLaborPriceOverride
          ? null
          : actionTypes.LABOR_OVERRIDE,
        laborRateNumber,
        laborRateOverride
      });

      dispatch({
        type: Actions.SET_CHANGED,
        payload: {
          field: "laborPrice",
          value: changed
        }
      });
    }
    dispatch({
      type: Actions.SET_ERRORS,
      payload: {
        field: "laborPrice",
        value: error
      }
    });
  };

  const onBlurOpCode = () => {
    const changed = localOpCode !== dmsOpcode;
    const error = !isEmpty(localOpCode) && localOpCode.length > 256;
    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "opCode",
        value: changed
      }
    });

    dispatch({
      type: Actions.SET_ERRORS,
      payload: {
        field: "opCode",
        value: error
      }
    });

    if (changed) {
      dispatch({
        type: Actions.SET_OPCODE,
        payload: localOpCode
      });
    }
  };

  return {
    BODY_SHOP,
    state,
    defaultserviceCodeID,
    laborHours,
    setLaborHours,
    originalLaborPrice,
    localLaborPrice,
    setLocalLaborPrice,
    localSubTypeId,
    setLocalSubTypeId,
    localCostAllocationSubTypeId,
    setLocalCostAllocationSubTypeId,
    newLocalCostAllocationName,
    setNewLocalCostAllocationName,
    localOpCode,
    setLocalOpCode,
    localLaborRate,
    disablePaytype,
    disableServiceType,
    defaultpayCodeID,
    serviceTypeTemp,
    isDMSPlusDealer,
    localVendorName,
    getLaborPrice,
    handlePayTypeChange,
    handlePayTypeSubTypeChange,
    handlePayTypeCostAllocationChange,
    handleNewPayTypeCostAllocationChange,
    handleLaborPriceChange,
    handleLaborTimeChange,
    handleOpCodeChange,
    handleServiceTypeChange,
    handleServiceContractChange,
    handleWarrantyPlanChange,
    localWarrantyPlanLabel,
    handleLaborRateChange,
    onFocusLaborRate,
    onBlurLaborRate,
    onBlurLaborTime,
    onFocusLaborPrice,
    onBlurLaborPrice,
    onBlurOpCode,
    payTypeConfirmationModal,
    showConfirmationModalForPayType,
    confirmPayTypeChange,
    dealerProperties,
    isServiceContractPayType,
    isInsuranceSelected,
    handleContractNumberChange,
    localContractNumber,
    isAlwaysShowLaborRateEnabled,
    localSpecialLaborPrice
  };
}
