import React, { useCallback, useState } from "react";
import Button from "@cx/ui/Button";
import TextArea from "@cx/ui/TextArea";
import ModalDialog from "@cx/ui/ModalDialog";
import "./reason-modal.scss";
import PropTypes from "prop-types";

/**
 * Reusable modal to prompt the user to enter a reason for performing an action.
 * @param props.showModal {bool} Whether or not the modal should be visible.
 * @param props.title {string} Title to display in the modal header.
 * @param props.message {message} Message to display below the modal header.
 * @param props.prepopulatedReason {prepopulatedReason?} Text with which to prepopulate the Reason input. (Optional; defaults to empty.)
 * @param props.cancelButtonText {string?} Text to display for the Cancel button. (Optional; defaults to "Cancel".)
 * @param props.submitButtonText {string} Text to display for the Submit button.
 * @param props.onCancel {function} Handler for when the Cancel button gets clicked.
 * @param props.onSubmit {function} Handler for when the Submit button gets clicked.
 * @returns {Element}
 */
const ReasonModal = props => {
  const {
    showModal,
    title,
    message,
    prepopulatedReason,
    cancelButtonText,
    submitButtonText,
    onCancel,
    onSubmit
  } = props;

  const [reason, setReason] = useState(prepopulatedReason ?? "");

  const buildSubmitButtonTooltip = useCallback(() => {
    if (reason.length === 0) {
      return "Reason is required";
    } else if (reason.length > 512) {
      return "Reason cannot be longer than 512 characters";
    } else {
      return "";
    }
  }, [reason]);

  return (
    <ModalDialog
      htmlId="reasonModal"
      data-testid="reasonModal"
      className="reason-modal"
      show={showModal ?? false}
      header={title}
      onHide={onCancel}
      footer={
        <>
          <Button
            htmlId="reasonModalCancelButton"
            data-testid="reasonModalCancelButton"
            type="button"
            buttonStyle="secondary"
            onClick={onCancel}
          >
            {cancelButtonText ?? "Cancel"}
          </Button>
          <Button
            htmlId="reasonModalSubmitButton"
            data-testid="reasonModalSubmitButton"
            type="button"
            buttonStyle="danger"
            onClick={() => onSubmit(reason)}
            disabled={reason.trim().length === 0 || reason.length > 512}
            title={buildSubmitButtonTooltip()}
          >
            {submitButtonText}
          </Button>
        </>
      }
    >
      <>
        <div className="message" data-testid="reasonModalMessage">
          {message}
        </div>
        <div className="label-with-character-count">
          <label htmlFor="reasonInput">
            Reason <span className="required-indicator">*</span>
          </label>
          <span
            id="reasonCharacterCount"
            data-testid="reasonCharacterCount"
            className={`character-count ${reason.length > 512 ? "error" : ""}`}
          >
            Character count: {reason.length}/512
          </span>
        </div>
        <TextArea
          htmlId="reasonInput"
          data-testid="reasonInput"
          name="reasonInput"
          label="Reason"
          onChange={cxEvent => setReason(cxEvent.target.value)}
          value={reason}
          displayLabel={false}
          rows={3}
        />
      </>
    </ModalDialog>
  );
};

ReasonModal.propTypes = {
  showModal: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  prepopulatedReason: PropTypes.string,
  cancelButtonText: PropTypes.string,
  submitButtonText: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
};

export default ReasonModal;
