/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import isNumber from "lodash/isNumber";
import has from "lodash/has";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import { isDev } from "../utils/is-dev";
import { freezeObject } from "../utils/object";
import { formatKeysToLowerCase } from "../utils/object";
import {
  CUSTOMER_SEARCH,
  VEHICLE_RESULTS,
  ADD_SERVICES,
  SERVICE_SUMMARY,
  customerPages,
  vehiclePages
} from "../constants/pages.constants";
import customerSchema from "../features/quote/schemas/customer.schema";
import stateSchema from "../features/quote/schemas/search-customer.schema";
import testingState from "./cache-data/mock-data.state";
import manufacturerCodes from "../features/page-wrapper/constants/manufacturer-codes.constants";
import {
  formatPartsFromRawMenuService,
  deltaPartsofQuoteMenuService
} from "../features/page-wrapper/utils/parts.util";
import { QuoteServiceTypes } from "../features/page-wrapper/constants/page-wrapper.constants";
import { appType } from "../api/xmmAxios";
import { isPartInstock } from "../PartsLookupModule/utils/helper.util";
import { extractNotes } from "../features/utils/data-transformer";
import { updatePartsWithSelectedProperty } from "../features/page-wrapper/utils/quote-util";

let defaultState = {
  debugMode: false,
  appType: null,
  dealerProperties: {},
  userPermissions: {},
  customerId: null,
  vehcileId: null,
  quoteId: null,
  accessType: null,
  editCustomerVehicleAccess: true,
  customer: null,
  vehicle: null,
  services: [],
  payTypes: [],
  serviceTypes: [],
  vendorList: [],
  serviceContracts: [],
  payTypeSubTypes: [],
  costAllocationTypes: [],
  specialOrderPriorities: [],
  internalAccount: "",
  partsPricingAndInventory: null,
  inventoryPartsLocation: [],
  showPageMask: false,
  isNewCustomer: false,
  isNewVehicle: false,
  isSkipCustomerFlow: false,
  pageTitle: "",
  pageSubtitle: "",
  mainSteps: [CUSTOMER_SEARCH, VEHICLE_RESULTS, ADD_SERVICES],
  lastMainStep: CUSTOMER_SEARCH,
  currentPage: CUSTOMER_SEARCH,
  quoteStepperBar: {
    currentStep: 0,
    servicesCount: 0,
    quoteTotal: 10000
  },
  makes: [],
  makeVariantMap: null,
  searchCustomer: {
    customers: [],
    query: ""
  },
  // @note: manage state when quote modified(add/remove/edit)services, modify parts
  isQuoteModified: false,
  quoteSummary: {
    quoteId: null,
    confirmationId: null,
    quoteStatus: null,
    quoteServices: [],
    message: "",
    appointmentCode: null,
    // @csr-logic
    advisor: null,
    technician: null,
    notes: []
  },
  originalQuoteSummary: null,
  navigationHistory: [CUSTOMER_SEARCH],
  showHeaderQuoteTotalButton: true,
  vehicleHasFutureAppointments: false,
  vehicleFutureAppointment: {
    hasFutureAppointments: false,
    appointmentDate: null
  },
  showCustomerVehicleCard: true,
  // this property could change to object to store date time & mail depends on the product requirements
  MailToCustomerSent: null,
  // read synonyms data from appContext
  synonymsList: [],
  isEditingService: false,
  currentEditingService: null,
  currentEditingMenuService: null,
  menuPackage: null,
  calculatedMenuPackage: null,
  // @todo-workaround: preload manufacturerCodes until catalog api is ready
  manufacturerCodes,
  toggleModal: false,
  isModifyParts: false,
  // @csr-logic
  priceAdjustmentType: null,
  priceAdjustmentItem: null,
  advisors: [],
  technicians: [],
  partsPersons: [],
  chargeAccountInfo: null,
  // summary menu options
  requestStatus: {
    showServiceAssistanceRequestAlert: false,
    showPartsAssistanceRequestAlert: false,
    completeServiceAssistance: false,
    completePartsAssistance: false,
    showAttentionRequestFailAlert: false,
    showResolvingRequestFailAlert: false
  },
  preRoServiceList: []
};

if (isDev()) {
  defaultState = {
    ...defaultState,
    ...testingState
  };
}

defaultState = freezeObject(defaultState);

const NewQuoteContext = React.createContext(defaultState);
const Actions = {
  WIPE_STATE: "WIPE_STATE",
  SET_DEBUG_MODE: "SET_DEBUG_MODE",
  SET_DEALER_PROPERTIES: "SET_DEALER_PROPERTIES",
  SET_USER_PERMISSIONS: "SET_USER_PERMISSIONS",
  SET_PAGE_TITLE: "SET_PAGE_TITLE",
  SET_PAGE_SUBTITLE: "SET_PAGE_SUBTITLE",
  SET_MAKES: "SET_MAKES",
  SET_MAKE_VARIANT_MAP: "SET_MAKE_VARIANT_MAP",
  SET_CUSTOMER: "SET_CUSTOMER",
  SET_CUSTOMER_CHARGE_ACCOUNT_INFO: "SET_CUSTOMER_CHARGE_ACCOUNT_INFO",
  ADD_CUSTOMER_VEHICLE: "ADD_CUSTOMER_VEHICLE",
  SET_VEHICLE: "SET_VEHICLE",
  SET_PAY_TYPES: "SET_PAY_TYPES",
  SET_SERVICE_TYPES: "SET_SERVICE_TYPES",
  SET_VENDOR_LIST: "SET_VENDOR_LIST",
  SET_SERVICE_CONTRACTS: "SET_SERVICE_CONTRACTS",
  SET_PAY_TYPE_SUB_TYPES: "SET_PAY_TYPE_SUB_TYPES",
  SET_PAY_TYPE_COST_ALLOCATION_TYPES: "SET_PAY_TYPE_COST_ALLOCATION_TYPES",
  SET_PAY_TYPE_NEW_COST_ALLOCATION_TYPE:
    "SET_PAY_TYPE_NEW_COST_ALLOCATION_TYPE",
  SET_SPECIAL_ORDER_PRIORITIES: "SET_SPECIAL_ORDER_PRIORITIES",
  SET_PARTS_PRICING_INVENTORY_FOR_EDIT_QUOTE_SERVICE:
    "SET_PARTS_PRICING_INVENTORY_FOR_EDIT_QUOTE_SERVICE",
  SET_INVENTORY_PARTS_LOCATION: "SET_INVENTORY_PARTS_LOCATION",
  SET_ALACARTE_SERVICES: "SET_ALACARTE_SERVICES",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  NAVIGATION: {
    CUSTOMER_START: "CUSTOMER_START",
    GO_BACK: "GO_BACK",
    START_OVER: "START_OVER"
  },
  SET_QUOTE: "SET_QUOTE",
  SET_ORIGINAL_QUOTE: "SET_ORIGINAL_QUOTE",
  SET_SEARCH_CUSTOMER: "SET_SEARCH_CUSTOMER",
  CLEAR_ALACARTE_SERVICES: "CLEAR_ALACARTE_SERVICES",
  CLEAR_QUOTE: "CLEAR_QUOTE",
  UPDATE_QUOTE: "UPDATE_QUOTE",
  UPDATE_QUOTE_SERVICE: "UPDATE_QUOTE_SERVICE",
  UPDATE_VEHICLE: "UPDATE_VEHICLE",
  UPDATE_CUSTOMER: "UPDATE_CUSTOMER",
  SET_IS_NEW_CUSTOMER: "SET_IS_NEW_CUSTOMER",
  SET_IS_SKIP_CUSTOMER_FLOW: "SET_IS_SKIP_CUSTOMER_FLOW",
  SET_PAGE_MASK: "SET_PAGE_MASK",
  SET_VEHICLE_HAS_FUTURE_APPOINTMENTS: "SET_VEHICLE_HAS_FUTURE_APPOINTMENTS",
  SET_VEHICLE_FUTURE_APPOINTMENT: "SET_VEHICLE_FUTURE_APPOINTMENT",
  SET_SHOW_CUSTOMER_VEHICLE_CARDS: "SET_SHOW_CUSTOMER_VEHICLE_CARDS",
  SET_SEND_MAIL_TO_CUSTOMER: "SET_SEND_MAIL_TO_CUSTOMER",
  UPDATE_WORKFLOW_ATTENTION_TAG: "UPDATE_WORKFLOW_ATTENTION_TAG",
  // @quote - read quote params from URL
  SET_QUOTE_PARAMS: "SET_QUOTE_PARAMS",
  SET_EDIT_CUSTOMER_VEHICLE_ACCESS: "SET_EDIT_CUSTOMER_VEHICLE_ACCESS",
  SET_SYNONYMS_LIST: "SET_SYNONYMS_LIST",
  SET_IS_EDITING_SERVICE: "SET_IS_EDITING_SERVICE",
  SET_CURRENT_EDITING_SERVICE: "SET_CURRENT_EDITING_SERVICE",
  SET_CURRENT_EDITING_MENU_SERVICE: "SET_CURRENT_EDITING_MENU_SERVICE",
  SET_TOGGLE_MODAL: "SET_TOGGLE_MODAL",
  SET_IS_MODIFY_PARTS: "SET_IS_MODIFY_PARTS",
  SET_CALCULATED_MENU_PACKAGE: "SET_CALCULATED_MENU_PACKAGE",
  SET_MENU_PACKAGE: "SET_MENU_PACKAGE",
  UPDATE_PAYTYPE_MENU_SERVICE: "UPDATE_PAYTYPE_MENU_SERVICE",
  UPDATE_SERVICETYPECODE_MENU_SERVICE: "UPDATE_SERVICETYPECODE_MENU_SERVICE",
  SET_IN_STOCK_PARTS_QUOTE_SERVICE: "SET_IN_STOCK_PARTS_QUOTE_SERVICE",
  SET_IN_STOCK_PARTS_QUOTE_MENU_SERVICE:
    "SET_IN_STOCK_PARTS_QUOTE_MENU_SERVICE",
  // @csr-logic
  SET_ADVISOR_TECHNICIAN_LIST: "SET_ADVISOR_TECHNICIAN_LIST",
  SET_ADVISOR: "SET_ADVISOR",
  SET_TECHNICIAN: "SET_TECHNICIAN",
  SET_PRICE_ADJUSTMENT_TYPE: "SET_PRICE_ADJUSTMENT_TYPE",
  SET_PRICE_ADJUSTMENT_ITEM: "SET_PRICE_ADJUSTMENT_ITEM",
  SET_TOTAL_TAXES: "SET_TOTAL_TAXES",
  SET_HANG_TAG: "SET_HANG_TAG",
  SET_VIN: "SET_VIN",
  SET_MILEAGE_IN: "SET_MILEAGE_IN",
  SET_TRANSPORTATION_LIST: "SET_TRANSPORTATION_LIST",
  SET_TRANSPORTATION: "SET_TRANSPORTATION",
  SET_PROMISED_TIME: "SET_PROMISED_TIME",
  SET_PRE_RO_SERVICE_LIST: "SET_PRE_RO_SERVICE_LIST",
  // quoteAssistance Request
  SET_REQUEST_STATUS: "SET_REQUEST_STATUS",
  SET_QUOTE_NOTES: "SET_QUOTE_NOTES",
  SET_QUOTE_MODIFIED: "SET_QUOTE_MODIFIED",
  SET_DEALERSHIP_NOTES: "SET_DEALERSHIP_NOTES",
  SET_UPDATE_PAYER: "SET_UPDATE_PAYER"
};

const afterPageNavigation = () => window.scrollTo(0, 0);

const getQuoteStepperBarCurrentStepByCurrentPage = currentPage => {
  let currentStep = 0;
  if (customerPages.includes(currentPage)) {
    currentStep = 0;
  } else if (vehiclePages.includes(currentPage)) {
    currentStep = 1;
  } else {
    currentStep = 2;
  }

  return currentStep;
};

const newQuoteReducer = (state, action) => {
  switch (action.type) {
    // @note: reset state excluding app level objects
    case Actions.WIPE_STATE: {
      return {
        ...defaultState,
        debugMode: state.debugMode,
        dealerProperties: state.dealerProperties,
        userPermissions: state.userPermissions
      };
    }
    case Actions.SET_DEALER_PROPERTIES: {
      return {
        ...state,
        dealerProperties: action.payload
      };
    }
    case Actions.SET_USER_PERMISSIONS: {
      return {
        ...state,
        userPermissions: action.payload
      };
    }
    case Actions.SET_DEBUG_MODE: {
      return {
        ...state,
        debugMode: action.payload
      };
    }
    case Actions.SET_PAGE_MASK: {
      return {
        ...state,
        showPageMask: action.payload
      };
    }
    case Actions.SET_MAKE_VARIANT_MAP: {
      return {
        ...state,
        makeVariantMap: action.payload
      };
    }
    case Actions.SET_MAKES: {
      return {
        ...state,
        makes: action.payload
      };
    }
    case Actions.SET_QUOTE: {
      const quote = action.payload;
      return {
        ...state,
        quoteSummary: {
          ...quote
        }
      };
    }
    case Actions.SET_ORIGINAL_QUOTE: {
      const quote = action.payload;
      return {
        ...state,
        originalQuoteSummary: {
          ...quote
        }
      };
    }
    case Actions.SET_SEARCH_CUSTOMER: {
      action.payload.customers = action.payload.customers.map(customer => {
        const phoneNumbers = {};
        if (customer.phoneNumbers.length) {
          for (const phoneNumber of customer.phoneNumbers) {
            const phoneNumberType = phoneNumber.type.toLowerCase();
            phoneNumbers[phoneNumberType] = phoneNumber.digits;
          }
        } else {
          phoneNumbers["work"] = null;
          phoneNumbers["mobile"] = null;
          phoneNumbers["home"] = null;
        }
        customer.phoneNumbers = phoneNumbers;

        return customer;
      });

      const searchCustomer = stateSchema.searchCustomer.cast(action.payload, {
        stripUnknown: true
      });

      return {
        ...state,
        searchCustomer
      };
    }
    case Actions.SET_CUSTOMER: {
      const { payload } = action;
      const { customer, isNewCustomer } = payload;
      if (payload.customer) {
        customer.contactInfo.phoneNumbers = formatKeysToLowerCase(
          customer.contactInfo.phoneNumbers
        );
        // This if block is used when we search for customer and commonConsumerId is sent as ccId
        if (!isEmpty(customer.ccId) && !isNull(customer.ccId)) {
          customer["commonConsumerId"] = customer["ccId"];
          delete customer["ccId"];
          // Get quote flow will have commonConsumerId coming from quoting api as commonconsumerId
          // However, we needed a work around to access the field so I added this tempCCID field
          // which gives as the commonConsumerId
        } else if (!isEmpty(customer.tempCCID) && !isNull(customer.tempCCID)) {
          customer.commonConsumerId = customer["tempCCID"].toString();
        }
      }
      return {
        ...state,
        customer,
        isNewCustomer
      };
    }

    case Actions.SET_CUSTOMER_CHARGE_ACCOUNT_INFO: {
      const chargeAccountInfo = action.payload;
      return {
        ...state,
        chargeAccountInfo
      };
    }
    case Actions.ADD_CUSTOMER_VEHICLE: {
      return {
        ...state,
        customer: {
          ...state.customer,
          vehicles: [...state.customer.vehicles, action.payload]
        }
      };
    }
    case Actions.SET_VEHICLE: {
      return {
        ...state,
        vehicle: action.payload
      };
    }
    case Actions.SET_ALACARTE_SERVICES: {
      return {
        ...state,
        services: action.payload
      };
    }
    case Actions.SET_PAGE_TITLE: {
      return {
        ...state,
        pageTitle: action.payload
      };
    }
    case Actions.SET_PAGE_SUBTITLE: {
      return {
        ...state,
        pageSubtitle: action.payload
      };
    }
    case Actions.SET_CURRENT_PAGE: {
      afterPageNavigation();
      let currentPage = action.payload;
      const showHeaderQuoteTotalButton =
        currentPage === SERVICE_SUMMARY ? false : true;
      const navigationHistory = [...state.navigationHistory];
      navigationHistory.push(currentPage);

      const quoteStepperBar = { ...state.quoteStepperBar };
      quoteStepperBar.currentStep =
        getQuoteStepperBarCurrentStepByCurrentPage(currentPage);

      return {
        ...state,
        currentPage,
        navigationHistory,
        quoteStepperBar,
        showHeaderQuoteTotalButton
      };
    }
    // @deprecation notice: will be eventually reused in the future
    case Actions.NAVIGATION.CUSTOMER_START: {
      afterPageNavigation();

      return {
        ...defaultState,
        makes: state.makes,
        searchCustomer: {
          ...state.searchCustomer
        },
        makeVariantMap: state.makeVariantMap
      };
    }
    case Actions.NAVIGATION.GO_BACK: {
      afterPageNavigation();

      const navigationHistory = state.navigationHistory;
      const quoteStepperBar = state.quoteStepperBar;
      let currentPage = CUSTOMER_SEARCH;
      if (navigationHistory.length >= 2) {
        navigationHistory.pop();
        currentPage = navigationHistory[navigationHistory.length - 1];
      }

      quoteStepperBar.currentStep =
        getQuoteStepperBarCurrentStepByCurrentPage(currentPage);

      const showHeaderQuoteTotalButton =
        currentPage === SERVICE_SUMMARY ? false : true;

      return {
        ...state,
        currentPage,
        navigationHistory,
        quoteStepperBar,
        showHeaderQuoteTotalButton
      };
    }
    // NOTE: Special action trigger for "Reset Quote", will reset Quote related state objects only;
    // exclude these pre-loaded data objects{ dealerProperties, userPermissions, debugMode, makes, makeVariantMap}
    case Actions.NAVIGATION.START_OVER: {
      afterPageNavigation();

      return {
        ...defaultState,
        debugMode: state.debugMode,
        dealerProperties: state.dealerProperties,
        userPermissions: state.userPermissions,
        makes: state.makes,
        makeVariantMap: state.makeVariantMap
      };
    }
    case Actions.UPDATE_VEHICLE: {
      const updatedVehicle = action.payload;

      // case1: update vehicle if customer has vehicles
      let customerVehicleFound = false;
      if (
        state.customer &&
        !isEmpty(state.customer.vehicles) &&
        !isEmpty(updatedVehicle)
      ) {
        state.customer.vehicles = state.customer.vehicles.map(vehicle => {
          if (vehicle.vehicleId === updatedVehicle.vehicleId) {
            customerVehicleFound = true;
            vehicle = {
              ...vehicle,
              ...updatedVehicle
            };
          }
          return vehicle;
        });
        // Note: if we didn't found the vehicle, it means that it's new and we put into the customer.vehicles context
        if (!customerVehicleFound && !isEmpty(updatedVehicle)) {
          state.customer.vehicles.push(updatedVehicle);
        }
      }
      // case2: add vehicle if new/old customer has no vehciles yet
      if (
        state.customer &&
        isEmpty(state.customer.vehicles) &&
        !isEmpty(updatedVehicle)
      ) {
        state.customer.vehicles?.push(updatedVehicle);
      }
      return {
        ...state,
        vehicle: updatedVehicle
      };
    }
    case Actions.UPDATE_CUSTOMER: {
      const { isNewCustomer, customer } = action.payload;
      const updatedCustomer = customer;

      let formattedCustomer = {
        ...updatedCustomer,
        contactInfo: {
          phoneNumbers: {
            work: updatedCustomer.contactInfo.phoneNumbers.WORK,
            mobile: updatedCustomer.contactInfo.phoneNumbers.MOBILE,
            home: updatedCustomer.contactInfo.phoneNumbers.HOME
          },
          address: {
            ...updatedCustomer.contactInfo.address
          }
        },
        commonConsumerId: updatedCustomer.ccId || ""
      };
      formattedCustomer =
        customerSchema.customerInState.cast(formattedCustomer);

      const customersInSearchResults = state.searchCustomer.customers.map(
        customer => {
          if (customer.personId === formattedCustomer.personId) {
            customer = {
              ...customer,
              ...formattedCustomer
            };
          }

          return customer;
        }
      );

      return {
        ...state,
        isNewCustomer,
        customer: formattedCustomer,
        searchCustomer: {
          ...state.searchCustomer,
          customers: customersInSearchResults
        }
      };
    }
    case Actions.CLEAR_ALACARTE_SERVICES: {
      return {
        ...state,
        services: []
      };
    }
    case Actions.CLEAR_QUOTE: {
      return {
        ...state,
        quoteSummary: {
          quoteId: null,
          confirmationId: null,
          quoteServices: [],
          message: ""
        }
      };
    }
    case Actions.UPDATE_QUOTE: {
      const updatedQuote = action.payload;
      // * Sorting services based on serviceLine Number in ascending order for CSR
      let sortedServices = [];
      if (appType === "CSR") {
        sortedServices = updatedQuote?.quoteServices?.sort(
          (a, b) =>
            parseFloat(a?.serviceLineNumber) - parseFloat(b?.serviceLineNumber)
        );
      }
      updatedQuote.quoteServices = !isEmpty(updatedQuote?.quoteServices)
        ? appType === "CSR"
          ? sortedServices
          : updatedQuote?.quoteServices
        : [];
      // update in quote
      if (
        state.quoteSummary &&
        state.quoteSummary.quoteId === updatedQuote.quoteId
      ) {
        state.quoteSummary = {
          ...state.quoteSummary,
          ...updatedQuote
        };
      }

      const { quoteServices } = updatedQuote;
      window.dispatchEvent(
        new CustomEvent("getPartsPricingForService", {
          detail: { quoteServices },
          bubbles: true,
          cancelable: true
        })
      );

      return {
        ...state
      };
    }
    case Actions.UPDATE_WORKFLOW_ATTENTION_TAG: {
      const { confirmationId, workflowAttentionTag, quoteStatus } =
        action.payload;
      if (
        state.quoteSummary &&
        state.quoteSummary.confirmationId === confirmationId
      ) {
        state.quoteSummary = {
          ...state.quoteSummary,
          workflowAttentionTag,
          quoteStatus
        };
      }
      return {
        ...state
      };
    }
    case Actions.SET_UPDATE_PAYER: {
      const { payerId } = action.payload;
      const payers = state?.quoteSummary?.payers || [];

      return {
        ...state,
        quoteSummary: {
          ...state.quoteSummary,
          payers: payers.map(payer =>
            payer.payerId === payerId ? { ...payer, ...action.payload } : payer
          )
        }
      };
    }
    case Actions.UPDATE_QUOTE_SERVICE: {
      const updatedQuote = action.payload;
      // add or remove services from quote
      if (
        state.quoteSummary &&
        state.quoteSummary.quoteId === updatedQuote.quoteId
      ) {
        state.quoteSummary = {
          ...state.quoteSummary,
          quoteServices: updatedQuote.quoteServices
        };
      }
      return {
        ...state
      };
    }
    case Actions.SET_IS_NEW_CUSTOMER: {
      return {
        ...state,
        isNewCustomer: action.payload
      };
    }
    case Actions.SET_IS_SKIP_CUSTOMER_FLOW: {
      return {
        ...state,
        isSkipCustomerFlow: action.payload
      };
    }

    case Actions.SET_VEHICLE_HAS_FUTURE_APPOINTMENTS: {
      return {
        ...state,
        vehicleHasFutureAppointments: action.payload
      };
    }
    case Actions.SET_VEHICLE_FUTURE_APPOINTMENT: {
      return {
        ...state,
        vehicleFutureAppointment: action.payload
      };
    }
    case Actions.SET_SHOW_CUSTOMER_VEHICLE_CARDS: {
      return {
        ...state,
        showCustomerVehicleCard: action.payload === true
      };
    }
    case Actions.SET_SEND_MAIL_TO_CUSTOMER: {
      return {
        ...state,
        MailToCustomerSent: action.payload
      };
    }
    // @quote - set query params read from URL in context
    case Actions.SET_QUOTE_PARAMS: {
      const {
        customerId,
        vehicleId,
        quoteId,
        accessType,
        appType,
        isPartsView
      } = action.payload;
      console.log("QUOTE CONTEXT- CSR APPTYPE", action.payload);
      return {
        ...state,
        customerId,
        vehicleId,
        quoteId,
        accessType,
        appType,
        isPartsView
      };
    }
    case Actions.SET_EDIT_CUSTOMER_VEHICLE_ACCESS: {
      return {
        ...state,
        editCustomerVehicleAccess: action.payload
      };
    }
    case Actions.SET_SYNONYMS_LIST: {
      return {
        ...state,
        synonymsList: action.payload
      };
    }
    case Actions.SET_IS_EDITING_SERVICE: {
      return {
        ...state,
        isEditingService: action.payload
      };
    }

    //@todo-edit: rename to current_quote_service
    case Actions.SET_CURRENT_EDITING_SERVICE: {
      const quoteServiceObj = cloneDeep(action.payload);
      updatePartsWithSelectedProperty(quoteServiceObj.parts);
      console.log("updatePartsWithSelectedProperty", quoteServiceObj.parts);
      // fix - defaultPayTypeCode not saved in quote API, hence read field from unified response {rawService}
      const unifiedResponse =
        !isEmpty(quoteServiceObj) &&
        JSON.parse(quoteServiceObj.quoteRawService.rawService);
      quoteServiceObj.defaultPayTypeCode = get(
        unifiedResponse,
        "defaultPayTypeCode",
        ""
      );
      quoteServiceObj.defaultServiceTypeCode = get(
        unifiedResponse,
        "defaultServiceType",
        ""
      );
      // BUGFIX- workaround - extra fields (labor,labors,parts) added at quoteService(menu) to support menu rendering in summary page;
      // these extra fields to be removed before updating quote payload for existing quote
      if (quoteServiceObj.quoteServiceType === QuoteServiceTypes.MENU) {
        delete quoteServiceObj.labors;
        quoteServiceObj.parts = null;
        quoteServiceObj.labor = null;
      }
      console.log("newquote-current editing service", quoteServiceObj);
      return {
        ...state,
        currentEditingService: {
          ...quoteServiceObj,
          operationSource:
            quoteServiceObj?.operationSource ||
            quoteServiceObj?.quoteServiceType ||
            null,
          dealershipNotes: extractNotes(quoteServiceObj?.dealershipNotes)
        },
        currentEditingMenuService: null
      };
    }
    case Actions.SET_CURRENT_EDITING_MENU_SERVICE: {
      const { menuService, menuRecord } = action.payload;
      const rawMenuTypeRecord = JSON.parse(
        menuRecord.quoteRawService.rawService
      );
      const rawMenuService =
        rawMenuTypeRecord.services.find(
          service => service.id === menuService.extServiceId
        ) || {};
      // @todo: replace rawMenuService with parts field instead part
      const { vehicle } = state;
      const makeValue = vehicle?.make || "";
      rawMenuService.parts = formatPartsFromRawMenuService(
        rawMenuService?.part || [],
        makeValue
      );
      delete rawMenuService?.part;
      // this has catalog menuservice format, but not unified response format
      const rawMenuServiceString = JSON.stringify(rawMenuService);
      return {
        ...state,
        currentEditingService: null,
        currentEditingMenuService: {
          ...menuService,
          quoteRawService: {
            rawService: rawMenuServiceString
          },
          operationSource: QuoteServiceTypes.MENU,
          serviceKind: null,
          // We extract dealershipNotes from object array so they can be handled as string within the ui
          dealershipNotes: extractNotes(menuService.dealershipNotes)
        },
        menuPackage: menuRecord
      };
    }
    case Actions.SET_TOGGLE_MODAL: {
      return {
        ...state,
        toggleModal: action.payload
      };
    }
    // actions added to support paytypes
    case Actions.SET_PAY_TYPES: {
      return {
        ...state,
        payTypes: action.payload
      };
    }
    // actions added to support serviceTypes
    case Actions.SET_SERVICE_TYPES: {
      return {
        ...state,
        serviceTypes: action.payload
      };
    }
    // actions added to support vendor list
    case Actions.SET_VENDOR_LIST: {
      return {
        ...state,
        vendorList: action.payload
      };
    }
    // actions added to service contracts
    case Actions.SET_SERVICE_CONTRACTS: {
      return {
        ...state,
        serviceContracts: action.payload
      };
    }
    case Actions.SET_PAY_TYPE_SUB_TYPES: {
      return {
        ...state,
        payTypeSubTypes: action.payload
      };
    }
    case Actions.SET_PAY_TYPE_COST_ALLOCATION_TYPES: {
      return {
        ...state,
        costAllocationTypes: action.payload
      };
    }
    case Actions.SET_PAY_TYPE_NEW_COST_ALLOCATION_TYPE: {
      return {
        ...state,
        internalAccount: action.payload
      };
    }
    case Actions.SET_SPECIAL_ORDER_PRIORITIES: {
      return {
        ...state,
        specialOrderPriorities: action.payload
      };
    }

    // This action called to store DMS Parts data from open track API when triggered for quote service ready to edit
    case Actions.SET_PARTS_PRICING_INVENTORY_FOR_EDIT_QUOTE_SERVICE: {
      return {
        ...state,
        partsPricingAndInventory: action.payload
      };
    }
    case Actions.SET_INVENTORY_PARTS_LOCATION: {
      return {
        ...state,
        inventoryPartsLocation: action.payload
      };
    }
    case Actions.SET_IS_MODIFY_PARTS: {
      return {
        ...state,
        isModifyParts: action.payload
      };
    }
    case Actions.SET_QUOTE_NOTES: {
      return {
        ...state,
        quoteSummary: {
          ...state.quoteSummary,
          notes: action.payload
        }
      };
    }
    case Actions.SET_CALCULATED_MENU_PACKAGE: {
      return {
        ...state,
        calculatedMenuPackage: action.payload
      };
    }
    case Actions.SET_MENU_PACKAGE: {
      const selectedMenuPackage = state.quoteSummary.quoteServices.find(
        service => service.quoteServiceType === QuoteServiceTypes.MENU
      );
      let rawMenu = {};
      if (
        !isEmpty(selectedMenuPackage.quoteRawService) &&
        !isEmpty(selectedMenuPackage.quoteRawService.rawService)
      ) {
        rawMenu = {
          ...JSON.parse(selectedMenuPackage.quoteRawService.rawService)
        };
      }
      console.log(
        "new quote context (SET_MENU_PACKAGE) rawMenu/selectedMenuPackage",
        rawMenu,
        selectedMenuPackage
      );
      return {
        ...state,
        menuPackage: {
          selectedMenuPackage,
          // this object contains delta mutations
          rawService: {
            ...rawMenu,
            defaultPayTypeCode: selectedMenuPackage.payTypeCode,
            payTypeGroup: selectedMenuPackage.payTypeGroup,
            payTypeCode: selectedMenuPackage.payTypeCode,
            payTypeDescription: selectedMenuPackage.payTypeDescription,
            serviceTypeCode: selectedMenuPackage.serviceTypeCode,
            services: rawMenu.services.map(catalogService => {
              const savedService = selectedMenuPackage.menuServices.find(
                menuService => catalogService.id === menuService.extServiceId
              );
              // workaround: extract menu service from rawMenu json and save rawMenuService Json string to each menu service level
              const rawMenuService = rawMenu.services.find(
                service => service.id === catalogService.id
              );
              const rawMenuServiceString = JSON.stringify(rawMenuService);
              if (!savedService) {
                const refineParts = catalogService.part.map(part => {
                  return {
                    ...part,
                    dmsPending: false,
                    selected: true
                  };
                });
                return {
                  ...catalogService,
                  rawMenuService: rawMenuServiceString,
                  originalMenuServiceParts: refineParts,
                  finalLaborPrice: catalogService.scheduledLaborPrice || 0
                };
              }
              // TODO: This delta service should have catalog menu service API format with quote saved menuservice values
              const mergedSavedService = {
                ...catalogService,
                completedTime: savedService?.completedTime || null,
                paymentStatus: savedService?.paymentStatus || null,
                id: savedService.extServiceId,
                extServiceId: savedService.extServiceId,
                serviceMenuId: get(savedService, "serviceMenuId", null),
                quoteServiceLaborId:
                  savedService?.labor?.quoteServiceLaborId || null,
                name: savedService.serviceName,
                dmsOpcode: savedService.dmsOpcode,
                durationMins: savedService?.labor?.laborTime,
                subTypeId: savedService?.subTypeId,
                allocationSubTypeId: savedService?.allocationSubTypeId,
                internalAccount: savedService?.internalAccount,
                catalogLabor: savedService?.labor?.catalogLabor,
                duration: savedService?.labor?.laborTime,
                laborRateOverride: savedService?.labor?.laborRateOverride,
                laborRate: savedService?.labor?.laborRate,
                shopDurationMins: savedService.shopDuration,
                shopDuration: savedService.shopDuration,
                selectable: savedService.selectable,
                selectByDefault: true,
                defaultPayTypeCode: selectedMenuPackage.payTypeCode,
                payTypeGroup: selectedMenuPackage.payTypeGroup,
                payTypeCode: selectedMenuPackage.payTypeCode,
                payTypeDescription: selectedMenuPackage.payTypeDescription,
                serviceTypeCode: selectedMenuPackage.serviceTypeCode,
                // IMPORTANT: update pricing fields of catalog menu service with quote menu service level - final price fields
                price: savedService.servicePrice,
                scheduledLaborPrice: savedService.finalLaborPrice,
                partsPrice: savedService.finalPartsPrice,
                // We extract dealershipNotes from object array so they can be handled as string within the ui
                dealershipNotes: extractNotes(savedService?.dealershipNotes),
                // SAFE-Side: update calcuation logic fields with saved menu service in quote
                totalPrice: savedService.servicePrice,
                laborPrice: savedService.labor.laborPrice,
                finalPartsPrice: savedService.finalPartsPrice,
                finalLaborPrice: savedService.finalLaborPrice,
                totalLaborPriceOverride: savedService.totalLaborPriceOverride,
                totalPartsPriceOverride: savedService.totalPartsPriceOverride,
                totalPriceOverride: savedService.totalPriceOverride,
                cause: get(savedService, "cause", null),
                complaint: savedService?.complaint || null,
                correction: savedService?.correction || null,
                laborTaxCode:
                  savedService?.laborTaxCode ||
                  catalogService?.defaultTaxCode ||
                  null,
                // IMPORTANT: update menu service override flag fields {priceOverride,scheduledLaborPriceOverride,partsPriceOverride} with boolean values
                // with saved quote menu service override fields are {float values} to use common util - menu price calculation logic
                priceOverride: isNumber(savedService.totalPriceOverride),
                scheduledLaborPriceOverride: isNumber(
                  savedService.totalLaborPriceOverride
                ),
                partsPriceOverride: isNumber(
                  savedService.totalPartsPriceOverride
                ),
                units: savedService.units,
                mileage: savedService.mileage,
                rawMenuService: rawMenuServiceString,
                // fix: collect unsaved parts of catalog menu service, used to display in recommended parts tab
                originalMenuServiceParts: deltaPartsofQuoteMenuService(
                  catalogService,
                  savedService
                ),
                // collect saved parts under quote menu service, include custom parts as well
                part: savedService.parts.map(part => {
                  return {
                    ...part,
                    id: part.extPartId,
                    quoteServicePartId: part?.quoteServicePartId || null,
                    adjustedQuantity: part.adjustedQuantity,
                    description: part.description,
                    oemPartNumber: part.oemPartNumber,
                    partPriceSource: part.priceSource,
                    partType: part.partType,
                    unitPrice: part.unitPrice,
                    partsPrice: part.partPrice,
                    dmsPending: false,
                    selected: true,
                    dtDmsPartCode: part?.dtDmsPartCode || null,
                    costPrice: part?.costPrice || null,
                    unitCost: part?.unitCost || part?.costPrice || null,
                    unitCostOverride: part?.unitCostOverride || false
                  };
                }),
                technicians: savedService?.technicians || null
              };
              console.log(
                "saved service/mergedService",
                savedService,
                mergedSavedService
              );
              return mergedSavedService;
            })
          }
        }
      };
    }
    case Actions.UPDATE_PAYTYPE_MENU_SERVICE: {
      const { payTypeCode, payTypeDescription, payTypeGroup } = action.payload;
      return {
        ...state,
        menuPackage: {
          ...state.menuPackage,
          rawService: {
            ...state.menuPackage.rawService,
            payTypeCode,
            payTypeDescription,
            payTypeGroup,
            services: state.menuPackage.rawService.services.map(service => {
              return {
                ...service,
                payTypeCode,
                payTypeDescription,
                payTypeGroup,
                defaultPayTypeCode: payTypeCode
              };
            })
          }
        }
      };
    }

    case Actions.UPDATE_SERVICETYPECODE_MENU_SERVICE: {
      const { serviceTypeCode } = action.payload;
      return {
        ...state,
        menuPackage: {
          ...state.menuPackage,
          rawService: {
            ...state.menuPackage.rawService,
            serviceTypeCode,
            services: state.menuPackage.rawService.services.map(service => {
              return {
                ...service,
                serviceTypeCode,
                defaultServiceTypeCode: serviceTypeCode
              };
            })
          }
        }
      };
    }

    // call this action to update DMS parts attributes {in-stock, cost,bin,shelf } at quote service level in Summary page/RO details, when DMS open track API callback triggered
    case Actions.SET_IN_STOCK_PARTS_QUOTE_SERVICE: {
      // REFACTOR(variable name): parts is dmsParts from parts pricing lookup
      const { extServiceId, parts } = action.payload;
      const serviceIndex = state.quoteSummary.quoteServices.findIndex(
        service => String(service.extServiceId) === String(extServiceId)
      );
      const service = state.quoteSummary.quoteServices[serviceIndex];
      // Handle race condition where the quote slider gets closed before the parts lookup API call completes.
      if (!service) {
        return state;
      }
      const remainingQuantities = parts?.map(p => ({
        partNumber: p.partNumber,
        quantityAvailable: p.quantityAvailable
      }));
      const newParts = !isEmpty(service)
        ? service.parts.map(part => {
            const foundPart = parts.find(
              p => p.partNumber === part.oemPartNumber
            );
            // @note: when DMS API has no parts, util returns payload parts with {partNumber, manufacturerCode}
            // always verify action.payload.parts object has "quantityAvailable" or not; if Qty not there, show blank for stock label
            const isQtyExists =
              foundPart &&
              isEmpty(part.lifecycleState) &&
              has(foundPart, "quantityAvailable");
            const partQuantityAvailable =
              isQtyExists && !part.approver
                ? Number(foundPart.quantityAvailable)
                : null;
            let quantityAvailable;
            if (partQuantityAvailable) {
              const foundQty = remainingQuantities.find(
                q => q.partNumber === foundPart.partNumber
              );
              quantityAvailable = foundQty.quantityAvailable;
              foundQty.quantityAvailable = Math.max(
                foundQty.quantityAvailable,
                0
              );
            } else {
              quantityAvailable = partQuantityAvailable;
            }
            const inStock =
              foundPart &&
              isPartInstock(part.adjustedQuantity, quantityAvailable);
            // Below fields are updated to support RO-Parts dashboard for catalog services
            const costPrice = foundPart ? foundPart.costPrice : part.costPrice;
            const bin = foundPart ? foundPart.bin : null;
            const shelf = foundPart ? foundPart.shelf : null;

            return {
              ...part,
              ...(foundPart && isNumber(quantityAvailable) ? { inStock } : {}),
              bin,
              shelf,
              costPrice,
              dmsPending: false,
              quantityAvailable
            };
          })
        : [];
      const newService = { ...service, parts: newParts };
      return {
        ...state,
        quoteSummary: {
          ...state.quoteSummary,
          quoteServices: state.quoteSummary.quoteServices
            .slice(0, serviceIndex)
            .concat(newService)
            .concat(state.quoteSummary.quoteServices.slice(serviceIndex + 1))
        }
      };
    }
    // call this action to update DMS parts Attributes {in-stock, cost,bin,shelf} at menu-service level in Summary page/RO details, when DMS open track API callback triggered
    case Actions.SET_IN_STOCK_PARTS_QUOTE_MENU_SERVICE: {
      const { extServiceId, parts } = action.payload;
      const menuPackageIndex = state.quoteSummary.quoteServices.findIndex(
        service => service.quoteServiceType === QuoteServiceTypes.MENU
      );
      const menuPackage = state.quoteSummary.quoteServices[menuPackageIndex];
      const serviceIndex =
        !isEmpty(menuPackage) &&
        menuPackage.menuServices.findIndex(
          service => String(service.extServiceId) === String(extServiceId)
        );
      const menuService = menuPackage?.menuServices[serviceIndex];
      const newParts = !isEmpty(menuService)
        ? menuService?.parts?.map(part => {
            const foundPart = parts.find(
              p => p.partNumber === part.oemPartNumber
            );
            // @note: when DMS API has no parts, util returns payload parts with {partNumber, manufacturerCode}
            // always verify action.payload.parts object has "quantityAvailable" or not; if Qty not there, show blank for stock label
            const isQtyExists =
              foundPart && has(foundPart, "quantityAvailable");
            const qtyAvailable = isQtyExists
              ? Number(foundPart.quantityAvailable)
              : null;
            const inStock =
              foundPart && isPartInstock(part.adjustedQuantity, qtyAvailable);
            // This fields are updated to support RO-Parts dashboard for menu-services
            const costPrice = foundPart ? foundPart.costPrice : part.costPrice;
            const bin = foundPart ? foundPart.bin : null;
            const shelf = foundPart ? foundPart.shelf : null;
            // if(foundPart){
            //   console.log("IN STOCK MENU", foundPart);
            // }
            return {
              ...part,
              ...(foundPart && isNumber(qtyAvailable) ? { inStock } : {}),
              bin,
              shelf,
              costPrice,
              dmsPending: false,
              quantityAvailable: qtyAvailable
            };
          })
        : [];
      menuService.parts = newParts;
      menuPackage.menuServices[serviceIndex] = menuService;
      return {
        ...state,
        quoteSummary: {
          ...state.quoteSummary,
          quoteServices: state.quoteSummary.quoteServices
            .slice(0, menuPackageIndex)
            .concat(menuPackage)
            .concat(
              state.quoteSummary.quoteServices.slice(menuPackageIndex + 1)
            )
        }
      };
    }
    // @csr-logic
    case Actions.SET_PRICE_ADJUSTMENT_TYPE: {
      console.log("SET_PRICE_ADJUSTMENT_TYPE", action.payload);
      return {
        ...state,
        priceAdjustmentType: action.payload
      };
    }

    case Actions.SET_PRICE_ADJUSTMENT_ITEM: {
      console.log("SET_PRICE_ADJUSTMENT_ITEM", action.payload);
      return {
        ...state,
        priceAdjustmentItem: action.payload
      };
    }

    case Actions.SET_ADVISOR_TECHNICIAN_LIST: {
      console.log("SET_ADVISOR_TECHNICIAN_LIST", action.payload);
      const {
        serviceAdvisors: advisors,
        technicians,
        partsPersons
      } = action.payload;
      return {
        ...state,
        advisors,
        technicians,
        partsPersons
      };
    }
    case Actions.SET_TRANSPORTATION_LIST: {
      console.log("SET_TRANSPORTATION_LIST", action.payload);
      return {
        ...state,
        transportationList: action.payload
      };
    }
    case Actions.SET_ADVISOR: {
      console.log("SET_ADVISOR", action.payload);

      const serviceWriter = action.payload;
      return {
        ...state,
        quoteSummary: {
          ...state.quoteSummary,
          serviceWriter
        }
      };
    }
    case Actions.SET_TECHNICIAN: {
      console.log("SET_TECHNICIAN", action.payload);
      const technician = action.payload;
      return {
        ...state,
        quoteSummary: {
          ...state.quoteSummary,
          technician
        }
      };
    }
    case Actions.SET_HANG_TAG: {
      console.log("SET_HANG_TAG", action.payload);
      const hangTag = action.payload;
      return {
        ...state,
        quoteSummary: {
          ...state.quoteSummary,
          hangTag
        }
      };
    }
    case Actions.SET_VIN: {
      console.log("SET_VIN", action.payload);
      const vin = action.payload;
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          vin
        },
        quoteSummary: {
          ...state.quoteSummary,
          vehicle: {
            ...state.quoteSummary.vehicle,
            vin
          }
        }
      };
    }
    case Actions.SET_MILEAGE_IN: {
      console.log("SET_MILEAGE_IN", action.payload);
      const mileageIn = action.payload;
      return {
        ...state,
        quoteSummary: {
          ...state.quoteSummary,
          mileageIn
        }
      };
    }
    case Actions.SET_TRANSPORTATION: {
      const transportation = action.payload;
      return {
        ...state,
        quoteSummary: {
          ...state.quoteSummary,
          transportation
        }
      };
    }
    case Actions.SET_PROMISED_TIME: {
      const pickUpDateTime = action.payload;
      return {
        ...state,
        quoteSummary: {
          ...state.quoteSummary,
          pickUpDateTime
        }
      };
    }
    case Actions.SET_TOTAL_TAXES: {
      console.log("SET_TOTAL_TAXES", action.payload);
      const totalTaxes = action.payload;
      return {
        ...state,
        quoteSummary: {
          ...state.quoteSummary,
          totalTaxes
        }
      };
    }
    case Actions.SET_REQUEST_STATUS: {
      const { requestStatus, type, complete = false } = action.payload;
      if (type === "service") {
        if (complete) {
          return {
            ...state,
            requestStatus: {
              ...state.requestStatus,
              completeServiceAssistance: requestStatus
            }
          };
        } else {
          return {
            ...state,
            requestStatus: {
              ...state.requestStatus,
              showServiceAssistanceRequestAlert: requestStatus
            }
          };
        }
      }
      if (type === "parts") {
        if (complete) {
          return {
            ...state,
            requestStatus: {
              ...state.requestStatus,
              completePartsAssistance: requestStatus
            }
          };
        } else {
          return {
            ...state,
            requestStatus: {
              ...state.requestStatus,
              showPartsAssistanceRequestAlert: requestStatus
            }
          };
        }
      }
      if (type === "requested_fail") {
        return {
          ...state,
          requestStatus: {
            ...state.requestStatus,
            showAttentionRequestFailAlert: requestStatus
          }
        };
      }
      if (type === "completed_fail") {
        return {
          ...state,
          requestStatus: {
            ...state.requestStatus,
            showResolvingRequestFailAlert: requestStatus
          }
        };
      }
    }
    case Actions.SET_DEALERSHIP_NOTES: {
      return {
        ...state,
        currentEditingService: {
          ...state.currentEditingService,
          dealershipNotes: action.payload
        }
      };
    }
    // sq-logic only
    case Actions.SET_QUOTE_MODIFIED: {
      const payload = appType === "SQ" ? action.payload : false;
      return {
        ...state,
        isQuoteModified: payload
      };
    }
    // Action to preserve service IDs selected as PRE-RO.
    case Actions.SET_PRE_RO_SERVICE_LIST: {
      return {
        ...state,
        preRoServiceList: action.payload
      };
    }
    default: {
      console.log(`Unhandled action type: ${action.type}`);
    }
  }
};

const NewQuoteProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(newQuoteReducer, defaultState);
  const value = { state, dispatch };
  return (
    <NewQuoteContext.Provider value={value}>
      {children}
    </NewQuoteContext.Provider>
  );
};

NewQuoteProvider.propTypes = {
  children: PropTypes.node.isRequired
};

const useNewQuoteContext = () => {
  const context = React.useContext(NewQuoteContext);
  if (context === undefined) {
    throw new Error(
      "useNewQuoteContext must be used within a NewQuoteProvider"
    );
  }

  /**
   * Displays the page mask with wait indicator while an async action is happening.
   * (For example, blocking UI interaction while changes are being saved.)
   * @param wrappedFunction Async function that we want the UI blocked for.
   * @returns {Promise<void>}
   */
  const blockUntilCompleted = async wrappedFunction => {
    try {
      context.dispatch({
        type: Actions.SET_PAGE_MASK,
        payload: true
      });
      return await wrappedFunction();
    } catch (e) {
      console.log("Error", e);
      return e;
    } finally {
      context.dispatch({
        type: Actions.SET_PAGE_MASK,
        payload: false
      });
    }
  };

  return {
    blockUntilCompleted,
    state: context.state,
    dispatch: context.dispatch
  };
};

export { NewQuoteProvider, useNewQuoteContext, Actions, NewQuoteContext };
